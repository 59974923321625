import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum BooleanMapFilter {
  USER_REPORTED_FLU_SYMPTOMS = 'userReportedFluSymptoms',
  USER_REPORTED_COVID_SYMPTOMS = 'userReportedCovidSymptoms',
  USER_REPORTED_OTHER_SYMPTOMS = 'userReportedOtherSymptoms',
  USER_REPORTED_NO_SYMPTOMS = 'userReportedNoSymptoms',
  GOVERNMENT_DATA = 'governmentData',
}

export enum NonBooleanMapFilter {
  GOVERNMENT_DATA_VIRUS = 'governmentDataVirus',
  GOVERNMENT_COVID_ACTIVITY = 'governmentCovidActivity',
}

export enum GovernmentDataVirus {
  COVID = 'covid',
  FLU = 'flu',
}

export enum GovernmentCovidActivity {
  CASES = 'cases',
  DEATHS = 'deaths',
}

export type NonBooleanFilterValue =
  | GovernmentDataVirus
  | GovernmentCovidActivity;

export type MapFilters = {
  [BooleanMapFilter.USER_REPORTED_FLU_SYMPTOMS]: boolean;
  [BooleanMapFilter.USER_REPORTED_COVID_SYMPTOMS]: boolean;
  [BooleanMapFilter.USER_REPORTED_OTHER_SYMPTOMS]: boolean;
  [BooleanMapFilter.USER_REPORTED_NO_SYMPTOMS]: boolean;
  [BooleanMapFilter.GOVERNMENT_DATA]: boolean;
  [NonBooleanMapFilter.GOVERNMENT_DATA_VIRUS]: GovernmentDataVirus;
  [NonBooleanMapFilter.GOVERNMENT_COVID_ACTIVITY]: GovernmentCovidActivity;
};

export const mapFilters: MapFilters = {
  [BooleanMapFilter.USER_REPORTED_FLU_SYMPTOMS]: true,
  [BooleanMapFilter.USER_REPORTED_COVID_SYMPTOMS]: true,
  [BooleanMapFilter.USER_REPORTED_OTHER_SYMPTOMS]: false,
  [BooleanMapFilter.USER_REPORTED_NO_SYMPTOMS]: false,
  [BooleanMapFilter.GOVERNMENT_DATA]: true,
  [NonBooleanMapFilter.GOVERNMENT_DATA_VIRUS]: GovernmentDataVirus.COVID,
  [NonBooleanMapFilter.GOVERNMENT_COVID_ACTIVITY]:
    GovernmentCovidActivity.CASES,
};

export const mapFiltersSlice = createSlice({
  name: 'mapFilters',
  initialState: mapFilters,
  reducers: {
    updateBooleanMapFilter: (
      filters,
      action: PayloadAction<BooleanMapFilter>
    ) => {
      filters[action.payload] = !filters[action.payload];
    },
    updateMapFilter: (
      filters,
      action: PayloadAction<{
        filter: NonBooleanMapFilter;
        value: NonBooleanFilterValue;
      }>
    ) => {
      const { filter, value } = action.payload;
      return {
        ...filters,
        [filter]: value,
      };
    },
  },
});

export const {
  updateMapFilter,
  updateBooleanMapFilter,
} = mapFiltersSlice.actions;

export const isVisualizingFluData = (virus: GovernmentDataVirus): boolean =>
  virus === GovernmentDataVirus.FLU;

export const isVisualizingCovidData = (virus: GovernmentDataVirus): boolean =>
  virus === GovernmentDataVirus.COVID;

export const isVisualizingCovidCasesData = (
  virus: GovernmentCovidActivity
): boolean => virus === GovernmentCovidActivity.CASES;

export const isVisualizingCovidDeathsData = (
  virus: GovernmentCovidActivity
): boolean => virus === GovernmentCovidActivity.DEATHS;
