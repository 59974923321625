/* eslint-disable camelcase */
import { WellReport, UnwellReport } from './types';

export interface HeadOfHouseholdWellReport
  extends Omit<WellReport, 'household_member_id'> {
  email: string;
}

export interface HeadOfHouseholdUnwellReport
  extends Omit<UnwellReport, 'household_member_id'> {
  email: string;
  submitted?: boolean;
}

export interface HouseholdMemberWellReport extends Omit<WellReport, 'email'> {
  household_member_id: number;
}

export interface HouseholdMemberUnwellReport
  extends Omit<UnwellReport, 'email'> {
  household_member_id: number;
  submitted?: boolean;
}

export enum HealthStatus {
  WELL = 'WELL',
  UNWELL = 'UNWELL',
  SKIP = 'SKIP',
}

export enum HeadOfHouseholdHealthStatus {
  WELL = 'WELL',
  UNWELL = 'UNWELL',
}

export interface HeadOfHouseholdHealthReport {
  healthStatus: HeadOfHouseholdHealthStatus;
  wellReport?: HeadOfHouseholdWellReport;
  unwellReport?: HeadOfHouseholdUnwellReport;
  wellSubmissionToken?: string;
}

export interface HouseholdMemberHealthReport {
  name: string;
  healthStatus: HealthStatus;
  wellReport?: HouseholdMemberWellReport;
  unwellReport?: HouseholdMemberUnwellReport;
  wellSubmissionToken?: string;
}

export interface UnwellReportInProgress {
  memberName: string;
  memberId?: number;
}

export type HouseholdMembersHealthReport = {
  [memberID: number]: HouseholdMemberHealthReport;
};

export interface HouseholdReport {
  headOfHousehold?: HeadOfHouseholdHealthReport;
  householdMembers?: HouseholdMembersHealthReport;
  unwellReportInProgress?: UnwellReportInProgress;
}

export const householdReport: HouseholdReport = {
  headOfHousehold: null,
  householdMembers: null,
  unwellReportInProgress: null,
};
