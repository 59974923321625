import React, { useState } from 'react';
import { Drawer } from '../Drawer';
import * as Styled from './styles';

interface Props {
  pathName?: string;
}

export const MobileMenu: React.FC<Props> = ({ pathName }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <Styled.MobileMenuButtonWrapper>
      <Styled.MobileMenuButton onClick={() => setIsDrawerOpen(true)}>
        Menu
      </Styled.MobileMenuButton>
      <Drawer
        isOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
        pathName={pathName}
      />
    </Styled.MobileMenuButtonWrapper>
  );
};

export default MobileMenu;
