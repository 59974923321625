import React from 'react';
import { useSelector } from '~/store';
import Grid from '@material-ui/core/Grid';
import { useMapFilters } from '../useMapFilters';
import { UserReportedDataMapFilterItem } from './UserReportedDataMapFilterItem';
import { Trans } from 'react-i18next';
import { Card } from '../Card';

interface Props {
  symptomCounts: {
    fluSymptomCount: number;
    covidSymptomCount: number;
    otherSymptomCount: number;
    noSymptomCount: number;
  };
}

export const UserReportedDataCard: React.FC<Props> = ({ symptomCounts }) => {
  const {
    toggleUserReportedFluSymptoms,
    toggleUserReportedCovidSymptoms,
    toggleUserReportedOtherSymptoms,
    toggleUserReportedNoSymptoms,
  } = useMapFilters();

  const {
    userReportedFluSymptoms,
    userReportedCovidSymptoms,
    userReportedOtherSymptoms,
    userReportedNoSymptoms,
  } = useSelector(state => state.mapFilters);

  const {
    covidSymptomCount,
    fluSymptomCount,
    otherSymptomCount,
    noSymptomCount,
  } = symptomCounts;

  return (
    <Card title={<Trans i18nKey="LocationStats.userStats.title" />}>
      <Grid
        container
        direction="column"
        spacing={1}
        data-test="self-reported-data"
      >
        <UserReportedDataMapFilterItem
          symptom="covidSymptom"
          isVisible={userReportedCovidSymptoms}
          symptomStatsCount={covidSymptomCount}
          onChange={toggleUserReportedCovidSymptoms}
        />
        <UserReportedDataMapFilterItem
          symptom="fluSymptom"
          isVisible={userReportedFluSymptoms}
          symptomStatsCount={fluSymptomCount}
          onChange={toggleUserReportedFluSymptoms}
        />
        <UserReportedDataMapFilterItem
          symptom="otherSymptom"
          isVisible={userReportedOtherSymptoms}
          symptomStatsCount={otherSymptomCount}
          onChange={toggleUserReportedOtherSymptoms}
        />
        <UserReportedDataMapFilterItem
          symptom="noSymptom"
          isVisible={userReportedNoSymptoms}
          symptomStatsCount={noSymptomCount}
          onChange={toggleUserReportedNoSymptoms}
        />
      </Grid>
    </Card>
  );
};
