import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { GovernmentDataCard } from '~/components/Map/GovernmentDataCard';
import { UserReportedDataCard } from '~/components/Map/UserReportedDataCard';
import { useCovidOutbreakGovernmentData } from '~/state/hooks/useCovidOutbreakGovernmentData';
import { useUserReportedVirusOutbreakData } from '~/state/hooks/useUserReportedVirusOutbreakData';
import * as Styled from './styles';

export const MapFilters: React.FC = () => {
  const {
    isLoading: isUserReportedDataLoading,
    symptomCounts,
  } = useUserReportedVirusOutbreakData();
  const {
    isLoading: isGovernmentDataLoading,
  } = useCovidOutbreakGovernmentData();

  return (
    <Styled.Controls>
      <Styled.ControlContainer>
        {isUserReportedDataLoading ? (
          <CircularProgress size={20} />
        ) : (
          <UserReportedDataCard symptomCounts={symptomCounts} />
        )}
      </Styled.ControlContainer>

      <Styled.ControlContainer>
        {isGovernmentDataLoading ? (
          <CircularProgress size={20} />
        ) : (
          <GovernmentDataCard />
        )}
      </Styled.ControlContainer>
    </Styled.Controls>
  );
};
