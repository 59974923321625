import { householdReport, HouseholdReport } from './householdReport';

export enum ReportType {
  WELL = 'Well',
  UNWELL = 'Unwell',
}

export interface HealthReportState {
  household: HouseholdReport;
}

export const healthReportState: HealthReportState = {
  household: householdReport,
};
