import { useGlobalDispatch } from '~/context/global';
import { fetchAccount, activateUserAccount } from '~/requests/user';
import { logger, ONMFeature } from '~/services/logging';

interface UseAccountState {
  fetchAndUpdateAccountState: () => Promise<void>;
}

export const useAccountState = (): UseAccountState => {
  const dispatch = useGlobalDispatch();

  const fetchAndUpdateAccountState = () =>
    fetchAccount()
      .then(({ status, data }) => {
        if (status === 200 && data) {
          if (!data.isActive) {
            activateUserAccount().catch(error => {
              logger.error(ONMFeature.ACCOUNT_ACTIVATION, error);
            });
          }

          dispatch({ type: 'SET_USER_ACCOUNT_DATA', payload: data });
        }
      })
      .catch(error => {
        logger.error(ONMFeature.FETCH_USER_ACCOUNT, error);
        throw Error(error);
      });

  return { fetchAndUpdateAccountState };
};
