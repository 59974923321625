import React from 'react';
import { DrawerContent } from './DrawerContent';
import * as Styled from './styles';

interface Props {
  isOpen: boolean;
  closeDrawer: () => void;
  pathName?: string;
}

export const Drawer: React.FC<Props> = ({ isOpen, closeDrawer, pathName }) => {
  return (
    <Styled.Drawer open={isOpen} onClose={closeDrawer}>
      <Styled.DrawerContainer>
        <DrawerContent closeDrawer={closeDrawer} pathName={pathName} />
      </Styled.DrawerContainer>
    </Styled.Drawer>
  );
};
