import React from 'react';
import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import {
  IconButton,
  FilledInput,
  FormHelperText,
  FilledInputProps,
  Zoom as ZoomAnimated,
} from '@material-ui/core';

export const ZipCodeInputContainer: React.FC = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const ZipCodeInput: React.FC<FilledInputProps> = styled(
  FilledInput
).attrs({
  type: 'text',
  name: 'zipCode',
  disableUnderline: true,
})`
  && {
    .MuiFilledInput-input {
      font-size: 18px;
      padding: 0.8em;
    }

    height: 52px;
    width: 327px;
    padding-left: 0.6em;
    border-radius: 500px;
    font-family: ${props => props.theme.typography.fontFamily};
    transition: background-color 1s ease, color 1s ease;

    background-color: ${props =>
      props.disabled
        ? props.theme.palette.grey['300']
        : props.theme.palette.background.main};

    color: ${props => {
      if (props.disabled) return props.theme.palette.text.disabled;
      if (props.error) return props.theme.palette.error.main;
      return props.theme.palette.text.primary;
    }};

    border-width: 2px;
    border-style: solid;
    border-color: ${props =>
      props.error ? props.theme.palette.error.main : 'transparent'};

    ${breakpoint.sm`
      width: 340px;
    `}
  }
`;

export const ZipCodeInputIconButton = styled(IconButton).attrs({
  size: 'small',
})`
  && {
    height: 40px;
    width: 40px;
    color: ${props => props.theme.palette.common.white};
    margin-right: auto;
    background-color: ${props => props.theme.palette.primary.main};
    &:hover {
      background-color: ${props => props.theme.palette.primary.dark100};
    }
  }
`;

export const Zoom = styled(ZoomAnimated)`
  position: absolute;
`;

export const ZipCodeInputErrorText = styled(FormHelperText).attrs({
  'aria-label': 'zipcode error',
})`
  && {
    left: 50%;
    top: 100%;
    position: absolute;
    text-align: center;
    white-space: nowrap;
    transform: translateX(-50%);
    color: ${props => props.theme.palette.error.main};
  }
`;
