import React from 'react';
import * as Styled from './styles';

interface Props {
  title: string | React.ReactElement;
}

export const Card: React.FC<Props> = ({ title, children }) => (
  <Styled.Card>
    <Styled.CardHeader title={title} />
    {children}
  </Styled.Card>
);
