import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActivityLevel } from './ActivityLevel';
import { Grid, Typography } from '@material-ui/core';
import { ExternalLink } from '~/components/BaseElements';
import * as Styled from './styles';

export const FluActivityForm: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <Typography align="center" component="legend" style={{ fontWeight: 600 }}>
        {t('LocationStats.nonUserStats.fluActivity')}
      </Typography>
      <Styled.ActivityLevelContainer>
        <ActivityLevel level="minimal" />
        <ActivityLevel level="low" />
        <ActivityLevel level="moderate" />
        <ActivityLevel level="high" />
      </Styled.ActivityLevelContainer>
      <Styled.ActivityLevelContainer>
        <ActivityLevel level="noData" />
      </Styled.ActivityLevelContainer>
      <Styled.ActivityLevelContainer>
        <Styled.CardFooterCaption>
          <Link to="https://github.com/hrbrmstr/cdcfluview/">
            {t('LocationStats.nonUserStats.dataSource')}
          </Link>
        </Styled.CardFooterCaption>
      </Styled.ActivityLevelContainer>
    </Grid>
  );
};

const Link = ExternalLink as React.FC<{ to: string }>;
