import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';

const BackButton = () => {
  const { t } = useTranslation();
  return (
    <Styled.BackButton to="">
      <ArrowBackIcon />
      {t('Header.back')}
    </Styled.BackButton>
  );
};

export default BackButton;
