import styled from 'styled-components';
import breakpoint from '~/common/breakpoint';
import {
  Brand as UnstyledBrand,
  ExternalLink as UnstyledExternalLink,
  InternalLink,
} from '~/components/BaseElements';

export const Footer = styled.footer`
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
  top: auto;
  bottom: 0;
  text-align: center;

  ${breakpoint.sm`
    text-align: left;
  `}
`;

export const MenuNav = styled.nav`
  padding-top: 10px;
  display: grid;
  grid-auto-flow: row;
  column-gap: ${({ theme }) => `${theme.spacing(20)}px`};
  row-gap: ${({ theme }) => `${theme.spacing(2)}px`};
  margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  & a {
    color: ${props => props.theme.palette.common.white};
    font-family: ${props => props.theme.fonts.montserrat};
    font-size: 0.75rem;
  }
  ${breakpoint.sm`
    margin-bottom: 0;
  `}

  ${breakpoint.md`
  grid-template-rows: repeat(5, auto);
  grid-auto-flow: column;
  column-gap: ${({ theme }) => `${theme.spacing(4)}px`};
  `}
`;

export const SocialContainer = styled.div`
  border: none;
  overflow: hidden;
  margin-top: ${({ theme }) => `${theme.spacing(2)}px`};
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
  ${breakpoint.sm`
    text-align: left;
  `}
  ${breakpoint.md`
    text-align: right;
    margin-bottom: 0;
  `}

  & > button:not(:last-child) {
    margin-right: ${({ theme }) => `${theme.spacing(1)}px`};
  }

  & > button > svg {
    margin-bottom: -4px;
  }
`;

export const Link = styled(InternalLink)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const ExternalLink = styled(UnstyledExternalLink)`
  color: ${({ theme }) => theme.palette.common.white};
`;

export const Brand = styled(UnstyledBrand)`
  margin-bottom: ${({ theme }) => `${theme.spacing(1)}px`};
  text-align: left;
`;

export const AuxLinks = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${breakpoint.sm`
    text-align: left;
  `}
  ${breakpoint.md`
  margin-top: 0;
    text-align: right;
  `}
`;

export const Copyright = styled.div`
  font-size: 0.75rem;
  width: 100%;
  text-align: center;
  margin-top: ${props => props.theme.spacing(3)}px;
  font-family: ${props => props.theme.fonts.montserrat};
  ${breakpoint.sm`
    text-align: left;
  `}
`;
