import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import Grid from '@material-ui/core/Grid';

interface Props {
  id: string;
  image: string;
}

export const HowItWorksItem: React.FC<Props> = ({ id, image }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Styled.Item>
        <Styled.ItemContent>
          <Styled.Image src={image} alt={t(`HowItWorks.${id}.title`)} />
          <Styled.ItemTitle>{t(`HowItWorks.${id}.title`)}</Styled.ItemTitle>
          <Styled.Description>
            {t(`HowItWorks.${id}.description`)}
          </Styled.Description>
        </Styled.ItemContent>
      </Styled.Item>
    </Grid>
  );
};
