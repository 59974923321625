/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useContext } from 'react';
import { useGlobalState, useGlobalDispatch } from '~/context/global';
import { CountryContext } from '~/context/i18n';
import axios from 'axios';

interface CovidSpreadGeoJson {
  features: [];
  maxIRateDeath: number;
  maxIRateConfirm: number;
}

interface CovidOutbreakGovernmentData {
  covidDeaths: number;
  covidConfirmedCases: number;
  covidSpreadGeoJson: CovidSpreadGeoJson;
}

interface UseCovidOutbreakGovernmentData extends CovidOutbreakGovernmentData {
  isError: boolean;
  isLoading: boolean;
}

export const useCovidOutbreakGovernmentData = (): UseCovidOutbreakGovernmentData => {
  const [isError, setIsError] = useState(false);
  const dispatch = useGlobalDispatch();
  const {
    searchZipCode,
    statsByCountry,
    isGovernmentDataLoading,
  } = useGlobalState();

  const { country } = useContext(CountryContext);
  const countryCode = country.code.toUpperCase();

  const setLoading = (loading: boolean) =>
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading,
        loader: 'isGovernmentDataLoading',
      },
    });

  const { confirmedCases, deathCases, populationData } =
    statsByCountry[countryCode] || {};

  const { confirmedCasesZip, deathCasesZip, populationDataZip } =
    statsByCountry[countryCode]?.zipCodes[searchZipCode] ?? {};

  useEffect(() => {
    const hasFetchedData =
      deathCases !== undefined &&
      confirmedCases !== undefined &&
      populationData !== undefined;
    if (!hasFetchedData) {
      fetchGovernmentCountryData();
    }
  }, [country]);

  useEffect(() => {
    if (searchZipCode) {
      fetchGovernmentZipCodeData();
    }
  }, [searchZipCode]);

  const fetchGovernmentCountryData = async () => {
    const data = await fetchData(`/nonuserstats/${countryCode}`);

    if (data) {
      dispatch({
        type: 'SET_GOVERNMENT_COVID_OUTBREAK_DATA',
        payload: {
          country: countryCode,
          deathCases: data.covidDeaths,
          confirmedCases: data.covidConfirmedCases,
          populationData: data.covidSpreadGeoJson,
        },
      });
    }
  };

  const fetchGovernmentZipCodeData = async () => {
    const hasFetchedData =
      deathCasesZip !== undefined &&
      confirmedCasesZip !== undefined &&
      populationDataZip !== undefined;

    if (hasFetchedData || isGovernmentDataLoading) return;

    const data = await fetchData(
      `/nonuserstats/${countryCode}/${searchZipCode}`
    );

    if (data) {
      dispatch({
        type: 'SET_GOVERNMENT_COVID_OUTBREAK_DATA_FOR_ZIPCODE',
        payload: {
          country: countryCode,
          zipCode: searchZipCode,
          deathCases: data.covidDeaths,
          confirmedCases: data.covidConfirmedCases,
          populationData: data.covidSpreadGeoJson,
        },
      });
    }
  };

  const fetchData = async (
    url: string
  ): Promise<CovidOutbreakGovernmentData> => {
    try {
      setLoading(true);
      const signal = axios.CancelToken.source();
      const { data } = await axios.get(url, {
        cancelToken: signal.token,
      });

      const defaultCovidSpreadGeoJson: CovidSpreadGeoJson = {
        features: data.features ?? [],
        maxIRateDeath: data.maxDeath ?? 0,
        maxIRateConfirm: data.maxConfirm ?? 0,
      };

      return {
        covidDeaths: data.death,
        covidConfirmedCases: data.confirmedcase,
        covidSpreadGeoJson: defaultCovidSpreadGeoJson,
      };
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    isError,
    isLoading: isGovernmentDataLoading,
    covidDeaths: searchZipCode ? deathCasesZip : deathCases,
    covidSpreadGeoJson: searchZipCode ? populationDataZip : populationData,
    covidConfirmedCases: searchZipCode ? confirmedCasesZip : confirmedCases,
  };
};
