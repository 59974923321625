import React, { useContext } from 'react';
import { navigate } from 'gatsby';
import { CountryContext } from '~/context/i18n';
import { useTranslation } from 'react-i18next';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { useLanguageUrl } from '~/state/hooks/useLanguageUrl';
import { applyNewLangToRoute, isBrowser } from '~/common/generalUtils';
import { useGlobalDispatch } from '~/context/global';
import { CountryCode } from 'utils/pageContext';
import CAFlag from '~/assets/images/flag.canada.svg';
import USFlag from '~/assets/images/flag.usa.svg';
import MXFlag from '~/assets/images/flag.mexico.svg';
import * as Styled from './styles';

export const getCountryFlag = (code: CountryCode): JSX.Element => {
  switch (code) {
    case 'ca':
      return <Styled.Flag src={CAFlag} alt="Canada Flag" />;
    case 'us':
      return <Styled.Flag src={USFlag} alt="USA Flag" />;
    case 'mx':
      return <Styled.Flag src={MXFlag} alt="Mexico Flag" />;
    default:
      return <div />;
  }
};

const MenuLanguage = ({ pathName }: { pathName: string }): JSX.Element => {
  const { t } = useTranslation();
  const { country, countries, language, languageName } = useContext(
    CountryContext
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const oldLang = useLanguageUrl();
  const gDispatchObj = useGlobalDispatch();
  const route = pathName || '';

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRegionSelection = newLang => () => {
    // Reset the zipcode for the old country
    gDispatchObj({
      type: 'SET_MAP_SEARCH_ZIPCODE',
      payload: {
        zipCode: null,
      },
    });

    if (isBrowser) {
      navigate(applyNewLangToRoute({ route, newLang, oldLang }));
    }

    handleClose();
  };

  return (
    <>
      <Styled.ChangeLanguageButton
        startIcon={getCountryFlag(country.code)}
        aria-label={t('Header.language')}
        aria-controls="lang-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon color="secondary" />}
        data-test="lang_menu_dropdown"
      >
        <span className={'country-lang'}>
          {isMobile ? language : `${country.name} (${languageName})`}
        </span>
      </Styled.ChangeLanguageButton>

      <Menu
        id="lang-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {Object.keys(countries).map((countryCode: CountryCode) =>
          countries[countryCode].languages.map(item => {
            const newLang = `${countryCode}/${item.code}/`;
            return (
              <Styled.MenuItems
                key={newLang}
                value={newLang}
                onClick={handleRegionSelection(newLang)}
                selected={newLang === oldLang}
                data-test={item.code}
              >
                <ListItemIcon>{getCountryFlag(countryCode)}</ListItemIcon>
                <ListItemText primary={`${item.countryName} (${item.name})`} />
              </Styled.MenuItems>
            );
          })
        )}
      </Menu>
    </>
  );
};

export default MenuLanguage;
