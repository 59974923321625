import BgManReadBook from '~/assets/images/bg-man-book.jpg';
import breakpoint from '~/common/breakpoint';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

export const ImageDivider = styled.div`
  height: 350px;
  background-image: url(${BgManReadBook});
  background-size: cover;
  background-position: center center;

  ${breakpoint.md`
    height: 550px;
  `}
  ${breakpoint.sm`
    height: 616px;
  `}
`;

export const WhatIsItTitle = styled(Typography).attrs({
  variant: 'body1',
})`
  font-size: 20px;
  margin: 0 auto ${props => props.theme.spacing(3)}px;
  color: ${props => props.theme.palette.purple.main};
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
`;

export const WhyIsItSubtitle = styled(Typography).attrs({
  variant: 'body1',
})`
  margin: 0 auto ${props => props.theme.spacing(3)}px;
  font-weight: 600;

  ${breakpoint.xs`
  font-size: 24px;
  `}
  ${breakpoint.md`
  font-size: 30px;
  `}
`;

export const TextContainer = styled(Box)`
  position: relative;
  z-index: 1;
  padding: ${props =>
    `${props.theme.spacing(4)}px ${props.theme.spacing(
      2
    )}px ${props.theme.spacing(4)}px`};

  ${breakpoint.xs`
    text-align: center;
    justify-content: center;
  `}

  ${breakpoint.sm`
    text-align: left;
    padding: 60px 100px 40px;
  `}
  ${breakpoint.md`
    padding: 60px 50px 0px 125px;
    max-width: 600px;
  `}
  ${breakpoint.lg`
    padding-top: 85px;
    padding-left:150px;
    padding-right: 0;
    max-width: 600px;
  `}
`;

export const WhatIsItSection = styled(Box)`
  background-color: ${props => props.theme.palette.background.light100};
  text-align: center;
  justify-content: center;

  .MuiContainer-root {
    padding: ${props =>
      `${props.theme.spacing(9)}px ${props.theme.spacing(1)}px`};
  }

  h6 {
    max-width: 70%;
  }

  ${breakpoint.md`
    h2 {
      margin-bottom: 0;
    }

    h6 {
      text-align: left;
      margin-bottom: 0;
      max-width: none;
    }
  `}
`;

export const Description = styled(Typography).attrs({
  variant: 'body1',
})`
  font-size: 16px;
  margin: 0 auto ${props => props.theme.spacing(3)}px;
  font-weight: 400;
`;
