import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const Image = styled.img`
  width: ${props => props.width}px;
`;

export const MapboxCredit = styled(Typography)`
  font-size: 0.7rem;
  margin-bottom: 0.25rem;
`;

export const LogoWrapper = styled.div`
  margin: 0.4rem 0;
`;
