import React from 'react';
import MobileMenu from './MobileMenu';
import { useSelector } from '~/store';
import { AccountMenu } from './AccountMenu';
import AccountButtons from './AccountButtons';
import { Grid, Hidden } from '@material-ui/core';
import * as Styled from './styles';

interface Props {
  pathName?: string;
}

export const AuthHeader: React.FC<Props> = ({ pathName }) => {
  const { loggedIn } = useSelector(state => state.userAuth);

  return (
    <Styled.AuthHeaderWrapper>
      {loggedIn === undefined && <></>}
      {loggedIn ? (
        <>
          <Hidden mdUp>
            <Grid item>
              <MobileMenu pathName={pathName} />
            </Grid>
          </Hidden>
          <Hidden smDown>
            <AccountMenu />
          </Hidden>
        </>
      ) : (
        <Grid>
          <AccountButtons />
        </Grid>
      )}
    </Styled.AuthHeaderWrapper>
  );
};
