import { navigate as gatsbyNavigate } from 'gatsby';
import { useLanguageUrl } from './useLanguageUrl';

interface NavigateOptions {
  replace?: boolean;
  state?: { [key: string]: unknown };
}

interface UseNavigate {
  navigate: (path: string, options?: NavigateOptions) => void;
}

export const useNavigate = (): UseNavigate => {
  const baseUrl = useLanguageUrl();

  const navigate = (path: string, options?: NavigateOptions) => {
    return gatsbyNavigate(baseUrl + path, options);
  };

  return { navigate };
};
