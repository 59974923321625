import { P } from '~/components/BaseElements';
import { RadioGroup as UnstyledRadioGroup } from 'formik-mui';
import breakpoint from '~/common/breakpoint';
import Button from '@material-ui/core/Button';
import girlBg from '~/assets/images/bg-girl-thermometer.jpg';
import girlBgMobile from '~/assets/images/bg-girl-thermometer-mobile.jpg';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
import UnstyledCollapse from '@material-ui/core/Collapse';
import UnstyledToggleButton from '@material-ui/lab/ToggleButton';
import UnstyledDivider from '@material-ui/core/Divider';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const InfoText = styled(Typography).attrs(props => ({
  variant: props.variant || 'h6',
  component: 'p',
}))`
  text-align: left;
  margin-bottom: ${props => props.theme.spacing(5)}px;
`;

export const ReminderInfotext = styled(Typography).attrs(props => ({
  variant: props.variant || 'h6',
  component: 'p',
}))`
  margin-bottom: ${props => props.theme.spacing(5)}px;
  text-align: center;
`;

export const InfoTextItalic = styled(InfoText)`
  font-style: italic;
`;

export const ThanksText = styled(Typography)`
  margin-bottom: ${props => props.theme.spacing(3)}px;
  font-weight: normal;
  text-align: left;
  line-height: 160%;
  font-size: 27px;
`;

export const Hero = styled.section`
  position: relative;
  background-image: url(${girlBgMobile});
  background-size: cover;
  background-position: center bottom;
  padding: ${props => props.theme.spacing(8)}px 0;

  ${breakpoint.md`
    background-image: url(${girlBg});
  `}
`;

export const Title = styled(Typography).attrs({
  variant: 'h3',
})`
  text-shadow: 0 4px 3px rgba(0, 0, 0, 0.25);
  margin-bottom: 2rem;

  ${breakpoint.xs`
    font-size: 2.5rem;
  `}

  ${breakpoint.sm`
    font-size: 3.5rem;
  `}
`;

export const Subtitle = styled(Typography).attrs({
  variant: 'subtitle2',
})`
  margin: 1.5rem 0 0;

  ${breakpoint.md`
    margin: 1.75rem 0 0;
    font-size: 1.25rem;
  `}
`;

export const Rules = styled.p`
  font-size: 0.75rem;
  margin-top: 0;

  ${breakpoint.xs`
      width: 90%;
      max-width: 300px;
  `}

  ${breakpoint.sm`
    width: 60%;
    max-width: 600px;
  `}

  ${breakpoint.md`
    width: 100%;
  `}
`;

export const Footer = styled.p`
  font-size: 0.9rem;
`;

export const ButtonPhone = styled(Button)`
  display: block;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`;

export const Content = styled(Paper)`
  position: relative;
  z-index: 2;
  padding: 55px;
  background: ${props => alpha(props.theme.palette.common.black, 0.2)};
  border-radius: 22px;
  color: ${({ theme }) => theme.palette.common.white};
  padding: 30px 30px 20px;
  margin: 6rem 0 20px;
  max-width: 732px;

  ${breakpoint.sm`
    width: 100%;
  `}

  ${breakpoint.md`
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 55px;
    max-width: none;
    width: 820px;
  `};
`;

export const Label = styled(P)`
  color: ${props => props.theme.palette.common.black};
  font-size: 1rem;
  flex-grow: 0;
  flex-shrink: 0;

  ${breakpoint.md`
    width: 30em;
  `}

  ${breakpoint.lg`
    width: 45em;
  `}
`;

export const InputContainer = styled(Grid).attrs({
  item: true,
  xs: 12,
  direction: 'column',
  container: true,
})`
  padding: 1rem 0 1rem 0;
  ${breakpoint.sm`  padding: 2rem 0 2rem 0;`}
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;

  ${breakpoint.md`
    flex-direction: column;
    align-items: center;
  `}

  .MuiFormControl-fullWidth,
  .MuiTextField-root {
    width: 100%;
    background: ${props => alpha(props.theme.palette.common.black, 0.025)};
    border-radius: 4px;
    margin-top: 10px;
    ${breakpoint.md`
      margin: 0;
    `};
  }

  .MuiOutlinedInput-input {
    padding: 14px;
  }
  .MuiTypography-paragraph {
    font-weight: 500;
    font-size: 1rem;
  }
`;

export const NestedInputContainer = styled(InputContainer)`
  border-left: 3px solid ${props => props.theme.palette.grey[400]};
  align-items: flex-start;
  text-align: left;
  margin: 20px 0px 30px;
  ${breakpoint.md`
    align-items: flex-start;
  `}
`;

export const RadioGroup = styled(UnstyledRadioGroup)`
  display: flex;
  flex-direction: row;
`;

export const Collapse = styled(UnstyledCollapse).attrs({
  timeout: 500,
})`
  flex-grow: 1;

  .MuiGrid-item {
    padding: 12px;
  }
`;

export const ToggleButton = styled(UnstyledToggleButton)`
  position: relative;
  border-width: 3px !important;
  border-style: solid !important;
  border-color: transparent;
  width: 128px;
  height: 128px;
  padding: 19px 10px;
  background: ${props => alpha(props.theme.palette.common.white, 0.5)};
  transition: 0.2s;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.mono};
  text-transform: capitalize;
  color: ${props => props.theme.palette.tertiary.main} !important;
  border-radius: 4px !important;
  font-size: 0.75rem;
  margin: 0 7.5px 15px !important;

  &.Mui-selected {
    background: ${props => props.theme.palette.common.white} !important;
    border-color: ${props => props.theme.palette.tertiary.main};

    & svg {
      opacity: 1;
    }
  }

  &:focus,
  &:hover {
    background: ${props => props.theme.palette.common.white};
  }

  .MuiToggleButton-label {
    flex-direction: column;
    justify-content: space-around;
  }

  p {
    margin: 0;
  }
`;

export const ButtonGroup = styled(ToggleButtonGroup)`
  background: transparent;
  padding-top: 15px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const SubmitButton = styled(Button).attrs({
  type: 'submit',
  color: 'primary',
  variant: 'contained',
})``;

export const BackButton = styled(Button).attrs({
  variant: 'contained' && 'outlined',
  color: 'primary',
})`
  border-width: 2px;
  font-weight: 700;
  &:hover {
    border-width: 2px;
  }
`;

export const Divider = styled(UnstyledDivider)`
  width: 100%;
  margin: ${props => `${props.theme.spacing(2)}px 0px`};
`;

export const ErrorText = styled(Typography)`
  color: ${props => props.theme.palette.error.dark};
  font-size: 12px;
  text-align: left;
  max-width: 255px;
  ${breakpoint.sm`
    text-align: left;
  `}
`;

export const RegularCaseButton = styled(Button).attrs({
  variant: 'outlined',
  color: 'primary',
})`
  text-transform: none;
`;

export const BottomButtonGroup = styled.div`
  display: flex;
  margin: 20px 0px 30px;
`;
