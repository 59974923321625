export enum ONMFeature {
  JOIN = 'JOIN',
  SIGN_IN = 'SIGN_IN',
  FETCH_USER_ACCOUNT = 'FETCH_USER_ACCOUNT',
  ACCOUNT_ACTIVATION = 'ACCOUNT_ACTIVATION',
  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',
  INDIVIDUAL_WELL_REPORT = 'INDIVIDUAL_WELL_REPORT',
  INDIVIDUAL_UNWELL_REPORT = 'INDIVIDUAL_UNWELL_REPORT',
  INDIVIDUAL_REPORT_FROM_EMAIL = 'INDIVIDUAL_REPORT_FROM_EMAIL',
  HOUSEHOLD_WELL_REPORT = 'HOUSEHOLD_WELL_REPORT',
  REMOVE_HOUSEHOLD_MEMBER = 'REMOVE_HOUSEHOLD_MEMBER',
  RAPID_TEST_SURVEY = 'RAPID_TEST_SURVEY',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
}

export interface APIEndpointError extends Error {
  response?: {
    data?: {
      errorCode?: string;
    };
  };
}

export interface ErrorContext {
  userEmail?: string;
  browser?: string;
}
