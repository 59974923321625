import React from 'react';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';
import { useTranslation } from 'react-i18next';

export const FacebookShare: React.FC = () => {
  const { t } = useTranslation();

  return (
    <FacebookShareButton
      quote={t('Footer.facebookShareQuote')}
      url="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Foutbreaksnearme.org&amp;layout=button_count&amp;size=large&amp;appId=1639803052928366&amp;width=88&amp;height=28"
    >
      <FacebookIcon size={32} round />
    </FacebookShareButton>
  );
};

export const TwitterShare: React.FC = () => {
  const { t } = useTranslation();

  return (
    <TwitterShareButton
      url="https://www.outbreaksnearme.org/"
      hashtags={[
        t('Footer.hashtags.PublicHealthTrack'),
        t('Footer.hashtags.COVID19'),
        t('Footer.hashtags.COVID19Pandemic'),
      ]}
      title={t('Footer.twitterShareMessage')}
    >
      <TwitterIcon size={32} round />
    </TwitterShareButton>
  );
};
