import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from './Form';
import { Stats } from './Stats';
import { Grid, Typography } from '@material-ui/core';

export const CovidActivityForm: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column">
      <Typography align="center" component="legend" style={{ fontWeight: 600 }}>
        {t('LocationStats.nonUserStats.covidActivity')}
      </Typography>
      <Form />
      <Stats />
    </Grid>
  );
};
