import React, { useContext } from 'react';
import styled from 'styled-components';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import logoBCHSrc from '~/assets/images/bch-logo.svg';
import logoHARVSrc from '~/assets/images/harvard-logo.svg';
import logoUoftSrc from '~/assets/images/uoft-logo-white.svg';
import { ExternalLink } from '~/components/BaseElements';
import { CountryContext } from '~/context/i18n';

const bchUrl = 'https://www.childrenshospital.org';
const harvardUrl = 'https://hms.harvard.edu';
const uoftUrl = 'https://utoronto.ca';

const StyledLogoBch = styled.img`
  width: 218px;
`;

const StyledLogoHarvard = styled.img`
  width: 114px;
`;

const StyledLogoUoft = styled.img`
  width: 113px;
`;

type ExternalBrandProps = {
  footer?: boolean;
};

export const ExternalBrand = ({
  footer = false,
}: ExternalBrandProps): JSX.Element => {
  const { country } = useContext(CountryContext);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={footer && matches ? 'flex-end' : 'space-around'}
      spacing={2}
    >
      <Grid item>
        <Box px={1}>
          <ExternalLink to={bchUrl} aria-label="Boston Children's Hospital">
            <StyledLogoBch
              src={logoBCHSrc}
              alt="Boston Children's Hospital Logo"
            ></StyledLogoBch>
          </ExternalLink>
        </Box>
      </Grid>
      <Grid item>
        <Box px={4}>
          <ExternalLink to={harvardUrl} aria-label="Harvard Medical School">
            <StyledLogoHarvard
              src={logoHARVSrc}
              alt="Harvard Medical School Logo"
            ></StyledLogoHarvard>
          </ExternalLink>
        </Box>
      </Grid>
      {country.code === 'ca' && (
        <Grid item>
          <ExternalLink to={uoftUrl} aria-label="University of Toronto">
            <StyledLogoUoft
              src={logoUoftSrc}
              alt="University of Toronto Logo"
            ></StyledLogoUoft>
          </ExternalLink>
        </Grid>
      )}
    </Grid>
  );
};
