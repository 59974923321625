import breakpoint from '~/common/breakpoint';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const HowItWorksSection = styled.section`
  background-color: ${props => props.theme.palette.common.white};
  text-align: center;

  .MuiContainer-root {
    padding: ${props =>
      `${props.theme.spacing(9.375)}px ${props.theme.spacing(
        1
      )}px ${props.theme.spacing(12.5)}px 0`};
  }
`;

export const Item = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.theme.spacing(3)}px;

  ${breakpoint.md`
    align-items: flex-start;
    margin-top: ${props => props.theme.spacing(1)}px;
  `}
`;

export const ItemContent = styled.div`
  padding: 0 ${props => props.theme.spacing(2)}px;
  max-width: 351px;
  ${breakpoint.sm`
    text-align: left;
    padding: 0 ${props => props.theme.spacing(10)}px 0 0;
  `}
`;

export const Image = styled.img`
  height: 67px;
  ${breakpoint.sm`
    text-align: left;
    margin-top: ${props => props.theme.spacing(5)}px;
    padding-bottom: 22px;
  `}
`;

export const Title = styled(Typography).attrs(props => ({
  variant: 'subtitle1',
  ...props,
}))`
  color: ${props => props.theme.palette.purple.main};
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5;
  ${breakpoint.sm`
  text-align: left;
    max-width: auto;
    margin: ${props => `${props.theme.spacing(2)}px auto`};
  `}
`;

export const ItemTitle = styled.div`
  font-weight: 600;
  line-height: 1.5;
  font-family: ${props => props.theme.fonts.montserrat};
  margin-bottom: ${props => props.theme.spacing(1)}px;
  font-size: 20px;
  ${breakpoint.md`
    text-align: left;
  `}
`;

export const Description = styled(Typography).attrs(props => ({
  variant: 'body2',
  color: 'textSecondary',
  paragraph: true,
  ...props,
}))`
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding: 0;
  ${breakpoint.sm`
    text-align: left;
  `}
`;
