exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/AboutUs.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-account-settings-tsx": () => import("./../../../src/templates/AccountSettings.tsx" /* webpackChunkName: "component---src-templates-account-settings-tsx" */),
  "component---src-templates-bonus-questions-tsx": () => import("./../../../src/templates/BonusQuestions.tsx" /* webpackChunkName: "component---src-templates-bonus-questions-tsx" */),
  "component---src-templates-collaborators-js": () => import("./../../../src/templates/Collaborators.js" /* webpackChunkName: "component---src-templates-collaborators-js" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/Faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-forgot-password-tsx": () => import("./../../../src/templates/ForgotPassword.tsx" /* webpackChunkName: "component---src-templates-forgot-password-tsx" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/Home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-household-bonus-questions-tsx": () => import("./../../../src/templates/HouseholdBonusQuestions.tsx" /* webpackChunkName: "component---src-templates-household-bonus-questions-tsx" */),
  "component---src-templates-household-demographics-tsx": () => import("./../../../src/templates/HouseholdDemographics.tsx" /* webpackChunkName: "component---src-templates-household-demographics-tsx" */),
  "component---src-templates-household-report-submission-tsx": () => import("./../../../src/templates/HouseholdReportSubmission.tsx" /* webpackChunkName: "component---src-templates-household-report-submission-tsx" */),
  "component---src-templates-household-report-tsx": () => import("./../../../src/templates/HouseholdReport.tsx" /* webpackChunkName: "component---src-templates-household-report-tsx" */),
  "component---src-templates-household-vaccination-tsx": () => import("./../../../src/templates/HouseholdVaccination.tsx" /* webpackChunkName: "component---src-templates-household-vaccination-tsx" */),
  "component---src-templates-join-tsx": () => import("./../../../src/templates/Join.tsx" /* webpackChunkName: "component---src-templates-join-tsx" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/PrivacyPolicy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-reports-js": () => import("./../../../src/templates/Reports.js" /* webpackChunkName: "component---src-templates-reports-js" */),
  "component---src-templates-reset-password-tsx": () => import("./../../../src/templates/ResetPassword.tsx" /* webpackChunkName: "component---src-templates-reset-password-tsx" */),
  "component---src-templates-sign-in-tsx": () => import("./../../../src/templates/SignIn.tsx" /* webpackChunkName: "component---src-templates-sign-in-tsx" */),
  "component---src-templates-terms-and-condition-js": () => import("./../../../src/templates/TermsAndCondition.js" /* webpackChunkName: "component---src-templates-terms-and-condition-js" */),
  "component---src-templates-testing-tsx": () => import("./../../../src/templates/Testing.tsx" /* webpackChunkName: "component---src-templates-testing-tsx" */),
  "component---src-templates-thank-you-tsx": () => import("./../../../src/templates/ThankYou.tsx" /* webpackChunkName: "component---src-templates-thank-you-tsx" */),
  "component---src-templates-unwell-tsx": () => import("./../../../src/templates/Unwell.tsx" /* webpackChunkName: "component---src-templates-unwell-tsx" */),
  "component---src-templates-verify-account-tsx": () => import("./../../../src/templates/VerifyAccount.tsx" /* webpackChunkName: "component---src-templates-verify-account-tsx" */),
  "component---src-templates-welcome-tsx": () => import("./../../../src/templates/Welcome.tsx" /* webpackChunkName: "component---src-templates-welcome-tsx" */),
  "component---src-templates-well-tsx": () => import("./../../../src/templates/Well.tsx" /* webpackChunkName: "component---src-templates-well-tsx" */)
}

