import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { Props as ActivityLevelProps } from './ActivityLevel';

export const ActivityLevelContainer = styled(Grid).attrs({
  spacing: 1,
  container: true,
  justify: 'center',
})`
  && {
    padding-top: 1em;
  }
`;

export const ActivityLevelItemContainer = styled(Grid).attrs({
  item: true,
})`
  && {
    flex: 1;
  }
`;

export const ActivityLevelBar = styled.div<ActivityLevelProps>`
  width: 54px;
  height: 10px;
  border: ${props => (props.level === 'noData' ? '1px solid #E6E6E6' : 'none')};
  background-color: ${props =>
    props.theme.palette.fluActivityLevelColors[props.level]};
`;

export const ActivityLevelText = styled(Typography).attrs({
  variant: 'caption',
  align: 'center',
})``;

export const CardFooterCaption = styled(Typography).attrs({
  align: 'center',
  variant: 'caption',
  color: 'textSecondary',
})`
  && {
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${props => props.theme.typography.caption.fontSize};
    font-weight: ${props => props.theme.typography.caption.fontWeight};
  }
`;
