/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import { useGlobalState, useGlobalDispatch } from '~/context/global';
import { CountryContext } from '~/context/i18n';
import axios from 'axios';

interface UserReportedVirusOutbreakData {
  fluSymptomCount: number;
  covidSymptomCount: number;
  otherSymptomCount: number;
  noSymptomCount: number;
}

interface UseUserReportedVirusOutbreakData {
  isError: boolean;
  isLoading: boolean;
  symptomCounts: UserReportedVirusOutbreakData;
}

export const useUserReportedVirusOutbreakData = (): UseUserReportedVirusOutbreakData => {
  const [isError, setIsError] = useState(false);
  const dispatch = useGlobalDispatch();
  const {
    searchZipCode,
    statsByCountry,
    isSelfReportedDataLoading,
  } = useGlobalState();

  const { country } = useContext(CountryContext);
  const countryCode = country.code.toUpperCase();

  const {
    reportedCovidSymptoms,
    reportedFluSymptoms,
    reportedOtherSymptoms,
    reportedNoSymptoms,
  } = statsByCountry[countryCode] ?? {};

  const {
    reportedZipCovidSymptoms,
    reportedZipFluSymptoms,
    reportedZipOtherSymptoms,
    reportedZipNoSymptoms,
  } = statsByCountry[countryCode]?.zipCodes[searchZipCode] ?? {};

  const setLoading = loading =>
    dispatch({
      type: 'SET_LOADING',
      payload: {
        loading,
        loader: 'isSelfReportedDataLoading',
      },
    });

  useEffect(() => {
    const hasFetchedCountryData =
      reportedFluSymptoms !== undefined &&
      reportedCovidSymptoms !== undefined &&
      reportedOtherSymptoms !== undefined &&
      reportedNoSymptoms !== undefined;
    if (!hasFetchedCountryData) {
      fetchCountryData();
    }
  }, [country]);

  useEffect(() => {
    if (searchZipCode) {
      fetchZipCodeData();
    }
  }, [searchZipCode]);

  const fetchCountryData = async () => {
    const data = await fetchData(`/stats/${countryCode}`);

    if (data) {
      dispatch({
        type: 'SET_USER_REPORTED_VIRUS_OUTBREAK_DATA',
        payload: {
          country: countryCode,
          reportedCovidSymptoms: data.covidSymptomCount,
          reportedFluSymptoms: data.fluSymptomCount,
          reportedOtherSymptoms: data.otherSymptomCount,
          reportedNoSymptoms: data.noSymptomCount,
        },
      });
    }
  };

  const fetchZipCodeData = async () => {
    const dataHasBeenPreviouslyFetched =
      reportedZipFluSymptoms !== undefined &&
      reportedZipCovidSymptoms !== undefined &&
      reportedZipOtherSymptoms !== undefined &&
      reportedZipNoSymptoms !== undefined;

    if (dataHasBeenPreviouslyFetched) return;

    const normalizedZipCode = searchZipCode.toUpperCase();
    const selfReportedMarkerData =
      statsByCountry[countryCode]?.geoJsonMarkerData?.features ?? [];

    const selfReportedDataForZipcode = selfReportedMarkerData.find(
      marker => marker.properties.zipcode.toUpperCase() === normalizedZipCode
    );

    if (selfReportedDataForZipcode) {
      dispatch({
        type: 'SET_USER_REPORTED_VIRUS_OUTBREAK_DATA_FOR_ZIPCODE',
        payload: {
          country: countryCode,
          reportedCovidSymptoms: selfReportedDataForZipcode.properties.symptoms,
          reportedFluSymptoms:
            selfReportedDataForZipcode.properties.fluSymptoms,
          reportedOtherSymptoms:
            selfReportedDataForZipcode.properties.otherSymptoms,
          reportedNoSymptoms: selfReportedDataForZipcode.properties.noSymptoms,
          zipCode: searchZipCode,
        },
      });
    } else {
      const data = await fetchData(`/stats/${countryCode}/${searchZipCode}`);

      if (data) {
        dispatch({
          type: 'SET_USER_REPORTED_VIRUS_OUTBREAK_DATA_FOR_ZIPCODE',
          payload: {
            country: countryCode,
            reportedCovidSymptoms: data.covidSymptomCount,
            reportedFluSymptoms: data.fluSymptomCount,
            reportedOtherSymptoms: data.otherSymptomCount,
            reportedNoSymptoms: data.noSymptomCount,
            zipCode: searchZipCode,
          },
        });
      }
    }
  };

  const fetchData = async (
    url: string
  ): Promise<UserReportedVirusOutbreakData> => {
    try {
      setLoading(true);
      const signal = axios.CancelToken.source();
      const { data } = await axios.get(url, {
        cancelToken: signal.token,
      });

      return {
        covidSymptomCount: data.covidsymptoms ?? 0,
        fluSymptomCount: data.flulikesymptoms ?? 0,
        otherSymptomCount: data.othersymptoms ?? 0,
        noSymptomCount: data.nosymptoms ?? 0,
      };
    } catch (error) {
      if (!axios.isCancel(error)) {
        setIsError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    isError,
    isLoading: isSelfReportedDataLoading,
    symptomCounts: {
      fluSymptomCount: searchZipCode
        ? reportedZipFluSymptoms
        : reportedFluSymptoms,
      covidSymptomCount: searchZipCode
        ? reportedZipCovidSymptoms
        : reportedCovidSymptoms,
      otherSymptomCount: searchZipCode
        ? reportedZipOtherSymptoms
        : reportedOtherSymptoms,
      noSymptomCount: searchZipCode
        ? reportedZipNoSymptoms
        : reportedNoSymptoms,
    },
  };
};
