import styled from 'styled-components';
import { Grid, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const BannerContainer = styled(Grid).attrs({
  container: true,
  justify: 'center',
  alignItems: 'center',
})`
  background-color: ${({ theme }) =>
    alpha(theme.palette.background.darkGray, 0.3)};
  padding: 2rem;
`;

export const Text = styled(Typography).attrs({
  variant: 'body1',
})`
  /* font-size: 16px; */
  font-weight: 400;
`;
