import { VaccinationValue } from '~/common/vaccinations';

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY_THIRD_GENDER = 'NON_BINARY_THIRD_GENDER',
  OTHER = 'OTHER',
  PREFER_NOT_SAY = 'PREFER_NOT_SAY',
}

export enum Race {
  'WHITE' = '1',
  'BLACK' = '2',
  'AMERICAN_INDIAN' = '3',
  'ALASKA_NATIVE' = '4',
  'NATIVE_HAWAIIAN' = '5',
  'PACIFIC_ISLANDER' = '6',
  'ASIAN' = '7',
  'ANOTHER' = '8',
  'PREFER_NOT_TO_SAY' = '9',
  'MULTI_RACIAL' = '10',
}

export enum Ethnicity {
  'HISPANIC' = '1',
  'NOT_HISPANIC_OR_OTHERWISE_SPECIFIED' = '2',
  'PREFER_NOT_TO_SAY' = '3',
}

export enum Vaccine {
  FLU = 'flu',
  COVID = 'covid',
}

export enum CountryCode {
  US = 'US',
  CA = 'CA',
  MX = 'MX',
}

export interface Vaccinations {
  covid: VaccinationValue;
  flu: VaccinationValue;
  covidSeason: string;
  fluSeason: string;
}

export interface UserRace {
  race: { id: Race; name: string };
  userSubmittedRace?: string;
}

export interface UserEthnicity {
  ethnicity: { id: Ethnicity; name: string };
}

export interface HouseholdMember {
  id: number;
  name: string;
  gender: Gender;
  userRace: UserRace;
  userEthnicity: UserEthnicity;
  isActive: boolean;
  dateOfBirth: string;
  vaccinations: Vaccinations;
}

export interface Account {
  email?: string;
  gender?: Gender;
  userRace?: UserRace;
  userEthnicity?: UserEthnicity;
  nickname?: string;
  phoneNumber?: string;
  remindByEmail?: boolean;
  remindByPhone?: boolean;
  vaccinations?: Vaccinations;
  zipcode?: string;
  dateOfBirth?: string;
  countryCode?: CountryCode;
  isActive?: boolean;
  householdMembers?: HouseholdMember[];
}

export const account: Account = {};
