import React from 'react';
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { ThemeProvider as ScThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';

export const symptomsColors = {
  covidSymptom: '#F05496',
  fluSymptom: '#FF9600',
  otherSymptom: '#A546BE',
  noSymptom: '#00B7B6',
};

const theme = createTheme({
  fonts: {
    montserrat: 'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  palette: {
    text: {
      blue: '#0000ff',
    },
    background: {
      athensGray: '#fafafb',
      blue: '#1678f2',
      cerulean: '#00acee',
      darkGray: '#646464',
      gray: '#C8C8C8',
      gray90: '#E5E5E5',
      light100: '#F4F4F4',
      main: '#ECECEC',
    },
    divider: '#bdbdbd',
    error: { main: '#FF7270' },
    primary: {
      light400: '#2C9CDB',
      light300: '#4dafcd',
      light200: '#33a3c5',
      light100: '#1a98be',
      main: '#008CB7',
      dark100: '#00709A',
      dark200: '#00567D',
      dark300: '#003D62',
    },
    secondary: { main: '#816a84' },
    gray: {
      mid: '#666',
    },
    success: { main: '#00B7B6' },
    tertiary: { main: '#985ca7', dark: '#8a5298' },
    yellow: { main: '#ffe167' },
    purple: { main: '#A546BE' },
    red: { main: '#FFCCCC' },
    symptomsColors,
    fluActivityLevelColors: {
      minimal: '#E5E5E5',
      low: '#BCBCBC',
      moderate: '#828282',
      high: '#333333',
      noData: '#fff',
    },
  },
  typography: {
    fontFamily: 'Montserrat, Helvetica Neue, Helvetica, Arial, sans-serif',
  },
  overrides: {
    MuiFormControl: {
      root: {
        margin: '.875rem 0',
      },
    },
    MuiTableCell: {
      root: {
        borderBottomColor: '#dedede',
      },
    },
  },
  props: {
    MuiLink: {
      underline: 'always',
    },
  },
});

const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={theme}>
    <ScThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={2}>{children}</SnackbarProvider>
    </ScThemeProvider>
  </MuiThemeProvider>
);

export default ThemeProvider;
