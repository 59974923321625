export default {
  /* user */
  userCountry: '/viewer',
  userAccount: '/useraccount',
  userRegistration: '/useraccount/register',
  userVaccinations: '/useraccount/vaccinations',
  matchFNYEmail: '/useraccount/matchfnyemail',
  forgotPassword: '/useraccount/forgotpassword',
  changeEmail: '/useraccount/changeemail',

  /* household */
  householdMember: '/householdmember',

  /* report submissions */
  wellReport: '/usersubmission/well',
  unwellReport: '/usersubmission',
  wellReportBonusQuestions: '/usersubmission/bonusquestions',
  pairSubmissionsToAccount: '/usersubmission/pairsubmissionstoaccount',

  /* user reported symptoms markers */
  symptomsMarkers: '/markers',

  logging: '/logging',
};
