import { ONMFeature, APIEndpointError, ErrorContext } from './types';
import { logToBackend } from '~/requests/logging';

const logger = {
  error: (
    onmFeature: ONMFeature,
    error: APIEndpointError | Error,
    context?: ErrorContext
  ): void => {
    logToBackend({
      error,
      onmFeature,
      context: { ...context, browser: window?.navigator?.userAgent },
    });
  },
};

export { logger };
export * from './types';
