import React, { useEffect } from 'react';
import { setLoggedIn } from '~/store/user/userAuth';
import { useDispatch } from '~/store';
import {
  listenForAuthEvents,
  getCurrentAuthenticatedUser,
} from '~/services/auth';

export const AuthStatusWatcher: React.FC = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    const setAuthStatus = (loggedIn: boolean) =>
      dispatch(setLoggedIn(loggedIn));

    getCurrentAuthenticatedUser()
      .then(() => setAuthStatus(true))
      .catch(() => setAuthStatus(false));

    const removeListener = listenForAuthEvents({
      onLogin: () => setAuthStatus(true),
      onLogout: () => setAuthStatus(false),
      onTokenRefreshFailure: () => setAuthStatus(false),
    });

    return function cleanup() {
      removeListener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{props.children}</>;
};
