import React from 'react';
import { Switch } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { symptomsColors } from '~/context/Theme';
import * as Styled from '../../MapFilters/styles';

interface Props {
  isVisible: boolean;
  symptomStatsCount: number;
  symptom: 'covidSymptom' | 'fluSymptom' | 'otherSymptom' | 'noSymptom';
  onChange: () => void;
}

export const UserReportedDataMapFilterItem: React.FC<Props> = ({
  symptom,
  onChange,
  isVisible,
  symptomStatsCount,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <Styled.StatsContainerRow>
      <Styled.CustomListItemIcon color={symptomsColors[symptom]}>
        <Styled.CircleIcon />
      </Styled.CustomListItemIcon>
      <Styled.StatsTypeContainer>
        <Styled.StatsNum>
          {symptomStatsCount?.toLocaleString(i18n.language) ?? 0}
        </Styled.StatsNum>
        <Styled.StatsCaption>
          {t(`LocationStats.userStats.${symptom}`)}
        </Styled.StatsCaption>
      </Styled.StatsTypeContainer>
      <Switch
        color="primary"
        checked={isVisible}
        onChange={onChange}
        inputProps={{
          'aria-label': t('LocationStats.nonUserStats.toggleMarkers', {
            value: t(`LocationStats.userStats.${symptom}`),
          }),
        }}
      />
    </Styled.StatsContainerRow>
  );
};
