/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { CountryCode, LanguageCode } from 'utils/pageContext';
import axios from 'axios';

type UseViewerStats = [CountryCode, string, boolean, boolean];

const getViewerUrl = (
  viewerCountry: CountryCode,
  languageCode: LanguageCode
) => {
  let country = viewerCountry.toLowerCase();
  let language = languageCode || `en-${viewerCountry.toUpperCase()}`;

  if (!['ca', 'mx', 'us'].includes(country)) {
    country = 'us';
    language = 'en-US';
  }

  return `/${country}/${language}`;
};
export const useViewerStats = (
  country: CountryCode,
  language: LanguageCode
): UseViewerStats => {
  const [viewerCountry, setViewerCountry] = useState(country);
  const [viewerUrl, setViewerUrl] = useState('');
  const [isViewerLoading, setIsViewerLoading] = useState(true);
  const [isViewerError, setIsViewerError] = useState(false);

  const fetchViewerStats = async () => {
    try {
      setIsViewerLoading(true);
      let countryCode = country;
      if (!countryCode) {
        const { data } = await axios.get('/viewer');
        countryCode = data.countryCode;
      }
      setViewerCountry(countryCode);
      const viewerUrl = getViewerUrl(countryCode, language);
      setViewerUrl(viewerUrl);
      setIsViewerLoading(false);
    } catch (error) {
      setIsViewerError(true);
    }
  };

  useEffect(() => {
    fetchViewerStats();
  }, []);

  return [viewerCountry, viewerUrl, isViewerLoading, isViewerError];
};
