import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { useNavigate } from '~/state/hooks/useNavigate.ts';
import { Hidden, useMediaQuery } from '@material-ui/core';
import * as Styled from './styles';
import { ROUTES } from '~/config';

const AccountButtons = props => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { navigate } = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSignInButton = () => {
    navigate(ROUTES.signin);
  };
  const handleJoinButton = () => {
    navigate(ROUTES.join);
  };

  return (
    <Styled.AccountButtonsWrapper>
      <Styled.SignInButton
        onClick={handleSignInButton}
        edge="end"
        fullWidth={props.fullWidth || false}
        variant={isMobile ? 'outlined' : 'contained'}
        size={isMobile ? 'small' : 'medium'}
        data-test="sign_in_button"
      >
        {t('Account.signIn')}
      </Styled.SignInButton>
      <Hidden smDown>
        <Styled.JoinButton
          onClick={handleJoinButton}
          edge="end"
          fullWidth={props.fullWidth || false}
          variant="contained"
          size="medium"
          data-test="join_button"
        >
          {t('Account.join')}
        </Styled.JoinButton>
      </Hidden>
    </Styled.AccountButtonsWrapper>
  );
};

export default AccountButtons;
