import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';
import { Footer } from '~/components/Footer/index.tsx';
import MapSection from './MapSection';
import { HowItWorks } from './HowItWorks/index.tsx';
import { WhatIsIt } from '../Layout/WhatIsIt/index.tsx';

import { navigate } from 'gatsby';
import { useViewerStats } from '~/state/hooks/useViewer.ts';
import { trim } from '~/common/generalUtils';

const Layout = (props = {}) => {
  const { children, pageContext, location = {} } = props;
  const { hash, pathname = '' } = location;

  const hasNoPageContext = Object.keys(pageContext).length === 0;
  const [regionLangFromBrowser] =
    pathname.match(/^\/[a-z]{2}\/[a-z]{2}-[A-Z]{2}\/?/) || [];
  const [country, language] = trim(regionLangFromBrowser).split('/');

  // If there's no pageContext and we have the region and language on the url, lets pass it in to the useViewerStats
  const [, viewerUrl, isViewerLoading] = useViewerStats(country, language);

  // If still loading, it means there's no region defined, let's wait to see to which url we should
  // redirect the user.
  if (isViewerLoading) {
    return <div></div>;
  }

  // If doesn't have the pageContext it means there's no region on the url and we know what url we
  // should redirect the user to.
  if (hasNoPageContext) {
    let redirectRoute = pathname;

    // Add the region and language to the url if needed.
    if (!redirectRoute.startsWith(viewerUrl)) {
      redirectRoute = `${viewerUrl}/${trim(redirectRoute)}`;
    }

    // If it have a hash we're assuming the access was through a angular route, so lets apply
    // the hash as part of the new route.
    if (hash) {
      redirectRoute = `${trim(redirectRoute)}/${trim(hash.replace('#!', ''))}`;
    }

    navigate(`/${trim(redirectRoute)}/`, { replace: true });
    return <div></div>;
  }

  // All good, let's see if this page use the map, default to false.
  const withMap =
    (pageContext.layoutConfig && pageContext.layoutConfig.useMap) || false;

  return (
    <>
      <Header {...props} />
      <main>{children}</main>
      {withMap && (
        <>
          <MapSection />
          <WhatIsIt />
          <HowItWorks />
        </>
      )}
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
