import routes from '../routes';
import { snakecase } from '~/common/casing';
import { httpClient, Response } from '~/services/http';
import {
  Vaccinations,
  Gender,
  Account,
  Race,
  Ethnicity,
  CountryCode,
} from '~/context/global/user/account';
import { VaccinationValue } from '~/common/vaccinations';

export interface RegisterUserRequestBody {
  email: string;
  nickname: string;
  remindByEmail: boolean;
  remindByPhone: boolean;
  phoneNumber: string;
  countryCode: string;
}

export const registerUser = (
  requestBody: RegisterUserRequestBody,
  recaptchaToken: string
): Promise<Response<undefined>> => {
  return httpClient.post(routes.userRegistration, snakecase(requestBody), {
    headers: { recaptchaToken },
  });
};

export const fetchAccount = (): Promise<Response<Account>> => {
  return httpClient.get(routes.userAccount, {
    cancelPendingRequest: true,
  });
};

export interface UpdateUserAccountRequestBody {
  gender?: Gender;
  raceId?: Race;
  ethnicityId?: Ethnicity;
  userSubmittedRace?: string;
  countryCode: CountryCode;
  zipcode?: string;
  nickname?: string;
  dateOfBirth?: string;
  remindByEmail?: boolean;
  remindByPhone?: boolean;
  phoneNumber?: string;
}

export interface UpdateUserAccountResponse
  extends UpdateUserAccountRequestBody {
  isActive: boolean;
  vaccinations: Vaccinations;
}

export const updateUserAccount = (
  requestBody: UpdateUserAccountRequestBody
): Promise<Response<UpdateUserAccountResponse>> => {
  return httpClient.put<UpdateUserAccountResponse>(
    routes.userRegistration,
    snakecase(requestBody)
  );
};

export interface UpdateUserVaccinationsRequestBody {
  receivedFluVaccine?: VaccinationValue;
  fluSeason?: string;
  covidSeason?: string;
  receivedCovidVaccine?: VaccinationValue;
}

export const updateUserVaccinations = (
  requestBody: UpdateUserVaccinationsRequestBody
): Promise<Response<undefined>> => {
  return httpClient.post(routes.userVaccinations, snakecase(requestBody));
};

export const activateUserAccount = (): Promise<Response<Account>> => {
  return httpClient.patch(routes.userAccount);
};

interface GetUserCountryResponse {
  countryCode: string;
}

export const getUserCountry = (): Promise<Response<GetUserCountryResponse>> => {
  return httpClient.get<GetUserCountryResponse>(routes.userCountry);
};

interface MatchFNYEmailRequest {
  email: string;
}

interface MatchFNYEmailResponse {
  hasMatch: boolean;
}

export const matchFNYEmail = (
  requestBody: MatchFNYEmailRequest
): Promise<Response<MatchFNYEmailResponse>> => {
  return httpClient.post<MatchFNYEmailResponse>(
    routes.matchFNYEmail,
    snakecase(requestBody)
  );
};

export const handleChangeEmail = (
  newEmail: string
): Promise<Response<undefined>> => {
  return httpClient.post(routes.changeEmail, { newEmail });
};

export const handleForgotPassword = (
  email: string
): Promise<Response<undefined>> => {
  return httpClient.post(routes.forgotPassword, { email });
};
