import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UserAuth {
  loggedIn?: boolean;
}

const userAuth: UserAuth = {
  loggedIn: undefined,
};

export const userAuthSlice = createSlice({
  name: 'userAuth',
  initialState: userAuth,
  reducers: {
    setLoggedIn: (auth, action: PayloadAction<boolean>) => {
      auth.loggedIn = action.payload;
    },
  },
});

export const { setLoggedIn } = userAuthSlice.actions;
