import React from 'react';
import { userState as user } from './user';
import { healthReportState as healthReport } from './reports';
import { merge, isEmpty } from 'lodash';

const baseStats = {
  confirmedCases: 0,
  confirmedCasesZip: 0,
  deathCases: 0,
  deathCasesZip: 0,
  reportedNoSymptoms: 0,
  reportedCovidSymptoms: 0,
  reportedFluSymptoms: 0,
  reportedOtherSymptoms: 0,
  reportedZipCovidSymptoms: 0,
  reportedZipFluSymptoms: 0,
  reportedZipOtherSymptoms: 0,
  reportedZipNoSymptoms: 0,
  submissionCount: 0,
  populationData: {
    features: [],
    maxIRateConfirm: 0,
    maxIRateDeath: 0,
  },
  populationDataZip: {
    features: [],
    maxIRateConfirm: 0,
    maxIRateDeath: 0,
  },
};

const baseCountry = {
  ...baseStats,
  geoJsonMarkerData: null,
  zipCodes: {},
};

const initialState = {
  user,
  healthReport,
  startNumber: 1000000,
  aggregateSubmissions: 1000000,
  statsByCountry: {},
  searchZipCode: '',
  zipCodeSearchError: '',
  unwellFormDetails: {
    'step-1': {},
    'step-2': {},
    'step-3': {},
  },
  selectedVirusForOutbreakVisualization: 'covid',
  isGovernmentDataLoading: true,
  isSelfReportedDataLoading: true,
  isGeoJsonMarkerDataLoading: false,
  healthSubmissionTokens: [],
};

const GlobalStateContext = React.createContext(initialState);
const GlobalDispatchContext = React.createContext();

const globalReducer = (state, action) => {
  const draft = { ...state };

  const countryCode = (action.payload.country || '').toUpperCase();

  if (
    (action.payload.zipCode || action.payload.country) &&
    !draft.statsByCountry[countryCode]
  ) {
    draft.statsByCountry[countryCode] = {
      ...baseCountry,
    };
  }

  const countryStats = draft.statsByCountry[countryCode] || {};

  if (
    action.payload.zipCode &&
    !countryStats.zipCodes[action.payload.zipCode]
  ) {
    countryStats.zipCodes[action.payload.zipCode] = {};
  }

  switch (action.type) {
    case 'SET_AGGREGATE_SUBMISSIONS': {
      draft.aggregateSubmissions = action.payload.newAggregateSubmissions;
      break;
    }

    case 'SET_USER_REPORTED_VIRUS_OUTBREAK_DATA': {
      draft.searchZipCode = initialState.searchZipCode;
      countryStats.reportedNoSymptoms = action.payload.reportedNoSymptoms;
      countryStats.reportedCovidSymptoms = action.payload.reportedCovidSymptoms;
      countryStats.reportedFluSymptoms = action.payload.reportedFluSymptoms;
      countryStats.reportedOtherSymptoms = action.payload.reportedOtherSymptoms;
      break;
    }

    case 'SET_USER_REPORTED_VIRUS_OUTBREAK_DATA_FOR_ZIPCODE': {
      countryStats.zipCodes[action.payload.zipCode].reportedZipNoSymptoms =
        action.payload.reportedNoSymptoms;
      countryStats.zipCodes[action.payload.zipCode].reportedZipCovidSymptoms =
        action.payload.reportedCovidSymptoms;
      countryStats.zipCodes[action.payload.zipCode].reportedZipFluSymptoms =
        action.payload.reportedFluSymptoms;
      countryStats.zipCodes[action.payload.zipCode].reportedZipOtherSymptoms =
        action.payload.reportedOtherSymptoms;
      break;
    }

    case 'SET_USER_SUBMISSION_STATS': {
      draft.searchZipCode = initialState.searchZipCode;
      countryStats.submissionCount = action.payload.submissionCount;
      break;
    }

    case 'SET_USER_SUBMISSION_STATS_FOR_ZIP': {
      countryStats.zipCodes[action.payload.zipCode].submissionCount =
        action.payload.submissionCount;
      break;
    }

    case 'SET_GOVERNMENT_COVID_OUTBREAK_DATA': {
      draft.searchZipCode = initialState.searchZipCode;
      countryStats.deathCases = action.payload.deathCases;
      countryStats.populationData = action.payload.populationData;
      countryStats.confirmedCases = action.payload.confirmedCases;
      break;
    }

    case 'SET_GOVERNMENT_COVID_OUTBREAK_DATA_FOR_ZIPCODE': {
      countryStats.zipCodes[action.payload.zipCode].deathCasesZip =
        action.payload.deathCases;
      countryStats.zipCodes[action.payload.zipCode].populationDataZip =
        action.payload.populationData;
      countryStats.zipCodes[action.payload.zipCode].confirmedCasesZip =
        action.payload.confirmedCases;
      break;
    }

    case 'SET_MARKERS_FOR_COUNTRY': {
      draft.searchZipCode = initialState.searchZipCode;
      countryStats.geoJsonMarkerData = action.payload.markerGeoJsonData;
      break;
    }

    case 'SET_MAP_SEARCH_ZIPCODE': {
      draft.searchZipCode = action.payload.zipCode;
      break;
    }

    case 'SET_MAP_ZIPCODE_SEARCH_ERROR': {
      draft.zipCodeSearchError = action.payload.errorMessage;
      break;
    }

    case 'SET_UNWELL_FORM_DETAILS': {
      draft.unwellFormDetails[action.payload.stepName] =
        action.payload.formDetails;
      break;
    }

    case 'SET_HEAD_OF_HOUSEHOLD_UNWELL_FORM_DETAILS': {
      const { formDetails } = action.payload;
      const { headOfHousehold } = draft.healthReport.household;
      headOfHousehold.unwellReport = {
        ...headOfHousehold.unwellReport,
        ...formDetails,
      };
      break;
    }

    case 'SET_HOUSEHOLD_MEMBER_UNWELL_FORM_DETAILS': {
      const { formDetails, memberId } = action.payload;
      const { householdMembers } = draft.healthReport.household;
      householdMembers[memberId].unwellReport = {
        ...householdMembers[memberId].unwellReport,
        ...formDetails,
      };
      break;
    }

    case 'RESET_UNWELL_FORM_DETAILS': {
      draft.unwellFormDetails = {
        'step-1': {},
        'step-2': {},
        'step-3': {},
      };
      break;
    }

    case 'SELECT_VIRUS_FOR_OUTBREAK_VIZ': {
      draft.selectedVirusForOutbreakVisualization = action.payload;
      break;
    }

    case 'SET_LOADING': {
      draft[action.payload.loader] = action.payload.loading;
      break;
    }

    case 'SET_USER_DEMOGRAPHIC_DATA': {
      if (action.payload.gender) {
        draft.user.account.gender = action.payload.gender;
      }
      if (action.payload.zipcode) {
        draft.user.account.zipcode = action.payload.zipcode;
      }
      if (action.payload.race_id) {
        draft.user.account.userRace = { race: { id: action.payload.race_id } };
      }
      if (action.payload.ethnicity_id) {
        draft.user.account.userEthnicity = {
          ethnicity: { id: action.payload.ethnicity_id },
        };
      }
      if (action.payload.birthdate) {
        draft.user.account.dateOfBirth = action.payload.birthdate;
      }
      break;
    }

    case 'SET_USER_VACCINATION_DATA': {
      if (action.payload.received_flu_vaccine) {
        draft.user.account.vaccinations.flu =
          action.payload.received_flu_vaccine;
      }
      if (action.payload.flu_season) {
        draft.user.account.vaccinations.fluSeason = action.payload.flu_season;
      }
      if (action.payload.received_covid_vaccine) {
        draft.user.account.vaccinations.covid =
          action.payload.received_covid_vaccine;
      }
      if (action.payload.covid_season) {
        draft.user.account.vaccinations.covidSeason =
          action.payload.covid_season;
      }
      break;
    }

    case 'SET_USER_ACCOUNT_DATA': {
      if (action.payload) {
        draft.user.account = action.payload;
      }
      break;
    }

    case 'UPDATE_USER_ACCOUNT_DATA': {
      if (action.payload) {
        draft.user.account = { ...draft.user.account, ...action.payload };
      }
      break;
    }

    case 'ADD_HOUSEHOLD_MEMBER': {
      if (action.payload) {
        draft.user.account.householdMembers.push(action.payload);
      }
      break;
    }

    case 'EDIT_HOUSEHOLD_MEMBER': {
      if (action.payload) {
        draft.user.account.householdMembers = draft.user.account.householdMembers.map(
          member => {
            if (member.id === action.payload.id) {
              return { ...member, ...action.payload };
            }
            return member;
          }
        );
      }
      break;
    }

    case 'DEACTIVATE_HOUSEHOLD_MEMBER': {
      if (action.payload) {
        draft.user.account.householdMembers = draft.user.account.householdMembers.map(
          member => {
            if (member.id === action.payload) member.isActive = false;
            return member;
          }
        );
      }
      break;
    }

    case 'SET_HOUSEHOLD_MEMBER_VACCINATIONS': {
      if (action.payload) {
        draft.user.account.householdMembers = draft.user.account.householdMembers.map(
          member => {
            if (member.id === action.payload.id) {
              if (action.payload.vaccines.received_flu_vaccine) {
                member.vaccinations.flu =
                  action.payload.vaccines.received_flu_vaccine;
              }
              if (action.payload.vaccines.received_covid_vaccine) {
                member.vaccinations.covid =
                  action.payload.vaccines.received_covid_vaccine;
              }
              return member;
            }
            return member;
          }
        );
      }
      break;
    }

    case 'SET_AUTH_STATUS': {
      draft.user.loggedIn = action.payload.loggedIn;
      break;
    }

    case 'SET_HOUSEHOLD_MEMBERS_HEALTH_REPORT': {
      const { householdMembersHealthReport } = action.payload;
      draft.healthReport.household.householdMembers = {
        ...householdMembersHealthReport,
      };
      break;
    }

    case 'SET_HOUSEHOLD_MEMBER_HEALTH_REPORT': {
      const { memberID, memberReport } = action.payload;
      const { householdMembers } = draft.healthReport.household;
      const { healthStatus } = householdMembers[memberID];
      if (healthStatus === 'WELL') {
        merge(householdMembers[memberID].wellReport, memberReport);
      } else if (healthStatus === 'UNWELL') {
        merge(householdMembers[memberID].unwellReport, memberReport);
      }
      break;
    }

    case 'SET_HEAD_OF_HOUSEHOLD_HEALTH_REPORT': {
      const { healthReport } = action.payload;
      const { headOfHousehold } = draft.healthReport.household;
      if (isEmpty(headOfHousehold)) {
        draft.healthReport.household.headOfHousehold = { ...healthReport };
      } else if (headOfHousehold.healthStatus === 'WELL') {
        merge(headOfHousehold.wellReport, healthReport);
      } else if (headOfHousehold.healthStatus === 'UNWELL') {
        merge(headOfHousehold.unwellReport, healthReport);
      }
      break;
    }

    case 'STORE_HEAD_OF_HOUSEHOLD_WELL_SUBMISSION_ID': {
      const { wellSubmissionId } = action.payload;
      const { headOfHousehold } = draft.healthReport.household;
      headOfHousehold.wellSubmissionId = wellSubmissionId;
      break;
    }

    case 'STORE_HOUSEHOLD_MEMBER_WELL_SUBMISSION_IDS': {
      const { householdMemberSubmissionIds } = action.payload;
      const { householdMembers } = draft.healthReport.household;
      householdMemberSubmissionIds.forEach(item => {
        householdMembers[item.householdMemberId].wellSubmissionId =
          item.submissionId;
      });
      break;
    }

    case 'SET_UNWELL_REPORT_IN_PROGRESS': {
      draft.healthReport.household.unwellReportInProgress = action.payload;
      break;
    }

    case 'MARK_UNWELL_REPORT_AS_SUBMITTED': {
      const { memberId } = action.payload;
      const {
        headOfHousehold,
        householdMembers,
      } = draft?.healthReport?.household;
      if (memberId) {
        householdMembers[memberId].unwellReport.submitted = true;
      } else {
        headOfHousehold.unwellReport.submitted = true;
      }
      break;
    }

    case 'CLEAR_HOUSEHOLD_HEALTH_REPORT': {
      draft.healthReport.household = action.payload;
      break;
    }

    default: {
      throw new Error('Unable to find action type');
    }
  }

  return draft;
};

export const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(globalReducer, initialState);
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = React.useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useRegionState must be used within a RegionProvider');
  }
  return context;
};

export const useGlobalDispatch = () => {
  const context = React.useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error('useRegionDispatch must be used within a CountProvider');
  }
  return context;
};
