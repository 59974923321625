export enum Feature {
  ACCOUNTS = 'accounts',
  HOUSEHOLD = 'household',
  RAPID_TESTING = 'rapidTesting',
}

const features = process.env.GATSBY_FEATURE_FLAGS_JSON ?? '{}';
const featureFlags: { [key: string]: boolean } = { ...JSON.parse(features) };

const setFeatureFlags = (features = '', isEnabled: boolean) => {
  features.split(',').forEach(feat => (featureFlags[feat] = isEnabled));
};

// Process URL variables at *run* time.
if (typeof window !== 'undefined' && window.location?.search) {
  const getFeaturesFromQueryString = (pattern: RegExp) => {
    return (window.location.search.match(pattern) || [])[1];
  };

  const flagsOn = getFeaturesFromQueryString(/\bfeature_on=([\w,]+)/);
  setFeatureFlags(flagsOn, true);

  const flagsOff = getFeaturesFromQueryString(/\bfeature_off=([\w,]+)/);
  setFeatureFlags(flagsOff, false);
}

export const isFeatureOn = (feature: Feature): boolean => {
  return !!featureFlags[feature];
};
