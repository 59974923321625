import React from 'react';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

export interface Props {
  level: 'minimal' | 'low' | 'moderate' | 'high' | 'noData';
}

export const ActivityLevel: React.FC<Props> = ({ level }) => {
  const { t } = useTranslation();

  return (
    <Styled.ActivityLevelItemContainer>
      <Grid container direction="column" alignItems="center">
        <Styled.ActivityLevelBar level={level} />
        <Styled.ActivityLevelText>
          {t(`LocationStats.nonUserStats.fluActivityLevels.${level}`)}
        </Styled.ActivityLevelText>
      </Grid>
    </Styled.ActivityLevelItemContainer>
  );
};
