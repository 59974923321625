import React, { useState } from 'react';
import { signOut } from '~/services/auth';
import { useTranslation } from 'react-i18next';
import { useGlobalState, useGlobalDispatch } from '~/context/global';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useNavigate } from '~/state/hooks/useNavigate';
import {
  Menu,
  useTheme,
  ListItemText,
  useMediaQuery,
  CircularProgress,
} from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import * as Styled from './styles';

export const AccountMenu: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const dispatch = useGlobalDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const username = useGlobalState().user?.account?.nickname;
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { navigate } = useNavigate();

  const goToAccountSettings = () => navigate('account-settings');

  const attemptSignout = () =>
    signOut()
      .then(() => {
        dispatch({
          type: 'SET_USER_ACCOUNT_DATA',
          payload: {},
        });
      })
      .catch(() => showSnackbar(t('Account.signOutError'), 'error'));

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Styled.DropDownButton
        data-test={'account_menu_dropdown'}
        fullWidth={isMobile}
        onClick={handleClick}
        endIcon={<ArrowDropDown color="secondary" />}
      >
        {username ? (
          <span>{t('Account.welcome', { username })}</span>
        ) : (
          <CircularProgress color="primary" size={18} />
        )}
      </Styled.DropDownButton>

      <Menu
        keepMounted
        id="signin-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        <Styled.MenuItems disabled>
          <ListItemText primary={t('Account.welcome', { username })} />
        </Styled.MenuItems>
        <Styled.HorizontalDivider />
        <Styled.MenuItems
          onClick={goToAccountSettings}
          onClickCapture={handleClose}
        >
          <ListItemText primary={t('AccountSettings.pageTitle')} />
        </Styled.MenuItems>
        <Styled.MenuItems onClick={attemptSignout} onClickCapture={handleClose}>
          <ListItemText primary={t('Account.signOut')} />
        </Styled.MenuItems>
      </Menu>
    </>
  );
};
