import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';

export const Copyright: React.FC = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <Styled.Copyright>
      &copy; 2019-{currentYear} {t('Footer.copyright')}
    </Styled.Copyright>
  );
};
