import React from 'react';
import * as Styled from './styles';
import { useTranslation } from 'react-i18next';
import { ArrowForward, ClearRounded } from '@material-ui/icons';
import {
  InputAdornment,
  IconButtonProps,
  CircularProgress,
} from '@material-ui/core';

interface Props extends IconButtonProps {
  loading: boolean;
  buttonAction: 'submit' | 'clear';
}

export const InputAdornmentButton: React.FC<Props> = ({
  onClick,
  loading,
  disabled,
  buttonAction,
}) => {
  const { t } = useTranslation();

  if (loading) {
    return (
      <InputAdornment position="end">
        <CircularProgress size={20} color="inherit" />
      </InputAdornment>
    );
  }

  return (
    <InputAdornment position="end">
      <Styled.ZipCodeInputIconButton
        onClick={onClick}
        disabled={disabled}
        aria-label={t('Map.search')}
      >
        <Styled.Zoom in={buttonAction === 'clear'}>
          <ClearRounded titleAccess="clear" aria-hidden={false} />
        </Styled.Zoom>
        <Styled.Zoom in={buttonAction === 'submit'}>
          <ArrowForward titleAccess="submit" aria-hidden={false} />
        </Styled.Zoom>
      </Styled.ZipCodeInputIconButton>
    </InputAdornment>
  );
};
