import React, { ReactNode } from 'react';
import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { PageContext, Country, CountryCode, Node } from 'utils/pageContext';

type CountryContextType = {
  country: Country;
  language: string;
  supportedCountries: CountryCode[];
  countries: { [key in CountryCode]: Country };
  adm1Boundaries: {
    edges: {
      node: Node;
    }[];
  };
  languageName: string;
};

export const AlternateLinksContext = React.createContext([]);
export const CountryContext = React.createContext<CountryContextType>(
  undefined
);

const I18nProvider = ({
  pageContext,
  children,
}: {
  pageContext: PageContext;
  children: ReactNode;
}): JSX.Element => {
  const {
    alternateLinks,
    countries,
    country,
    i18nResources,
    language,
    languageName,
    supportedCountries,
    adm1Boundaries,
  } = pageContext;

  const i18n = i18next
    .createInstance({
      lng: language,
      interpolation: {
        escapeValue: false,
        format: (value, format, lng) => {
          if (format === 'localeString') {
            return value.toLocaleString(lng);
          }

          if (format === 'localeString-bold') {
            return value.toLocaleString(lng).bold();
          }

          return value;
        },
      },
      initImmediate: false,
      resources: i18nResources,
    })
    .use(initReactI18next);

  i18n.init();

  return (
    <I18nextProvider i18n={i18n}>
      <AlternateLinksContext.Provider
        value={!!alternateLinks && alternateLinks}
      >
        <CountryContext.Provider
          value={{
            country,
            language,
            supportedCountries,
            countries,
            adm1Boundaries,
            languageName,
          }}
        >
          {children}
        </CountryContext.Provider>
      </AlternateLinksContext.Provider>
    </I18nextProvider>
  );
};

export default I18nProvider;
