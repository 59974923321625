import React from 'react';
import { ExternalLink } from '../Footer/styles';
import * as Styled from './styles';
import logoBCH from '~/assets/images/bch-logo.svg';
import logoHMS from '~/assets/images/harvard-logo.svg';
import logoUoft from '~/assets/images/uoft-logo-white.svg';

export const BostonChildrensHospital: React.FC = () => (
  <Styled.LogoWrapper>
    <ExternalLink
      to="https://www.childrenshospital.org"
      aria-label="Boston Children's Hospital"
    >
      <Styled.Image
        src={logoBCH}
        alt="Boston Children's Hospital Logo"
        width={218}
      />
    </ExternalLink>
  </Styled.LogoWrapper>
);

export const HarvardMedicalSchool: React.FC = () => (
  <Styled.LogoWrapper>
    <ExternalLink
      to="https://hms.harvard.edu"
      aria-label="Harvard Medical School"
    >
      <Styled.Image
        src={logoHMS}
        alt="Harvard Medical School Logo"
        width={114}
      />
    </ExternalLink>
  </Styled.LogoWrapper>
);

export const UniversityOfToronto: React.FC = () => (
  <Styled.LogoWrapper>
    <ExternalLink to="https://utoronto.ca" aria-label="University of Toronto">
      <Styled.Image
        src={logoUoft}
        alt="University of Toronto Logo"
        width={113}
      />
    </ExternalLink>
  </Styled.LogoWrapper>
);
