import React from 'react';
import styled from 'styled-components';
import { InternalLink } from '~/components/BaseElements';

import ONMLogo from '~/assets/images/onm-logo.svg';
import ONMLogoWhite from '~/assets/images/onm-logo-white.svg';
import ONMIcon from '~/assets/images/onm-icon.svg';
import breakpoint from '~/common/breakpoint';

export const Brand = ({ tagline, white, dataTest }) => {
  return (
    <BrandLogoStyle footerVersion={white} data-test={dataTest}>
      <InternalLink to="">
        <img
          src={white ? ONMLogoWhite : ONMLogo}
          alt="Outbreaks Near Me Logo"
        />
        {tagline && <h2>Community crowd sourced platform</h2>}
      </InternalLink>
    </BrandLogoStyle>
  );
};

export const MobileBrand = () => (
  <MobileBrandLogoStyle>
    <InternalLink to="">
      <img src={ONMIcon} alt="Outbreaks Near Me Logo" />
    </InternalLink>
  </MobileBrandLogoStyle>
);

const BrandLogoStyle = styled(({ footerVersion, children, ...props }) => (
  <div {...props}>{children}</div>
))`
  & img {
    padding-top: 4px;
    width: 160px;
    ${breakpoint.sm`
      width: 190px;
    `}
    ${breakpoint.md`
      width: 200px;
    `}
  }
`;

const MobileBrandLogoStyle = styled(({ children, ...props }) => (
  <div {...props}>{children}</div>
))`
  & img {
    padding-top: 4px;
    width: 40px;
  }
`;

export default Brand;
