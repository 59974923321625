import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '~/context/i18n';
import { RegisterOptions } from 'react-hook-form';
import { useGlobalDispatch } from '~/context/global';

interface UseZipCode {
  maxLength: number;
  ariaLabel: string;
  placeholder: string;
  validationRules: RegisterOptions;
  updateSearchZipCode: (zipCode: string) => void;
  clearSearchZipCode: () => void;
}

export const useZipCode = (): UseZipCode => {
  const { t } = useTranslation();
  const gDispatchObj = useGlobalDispatch();
  const { country } = useContext(CountryContext);

  const validationRules: RegisterOptions = {
    validate: {
      format: value => {
        const regex = new RegExp(country.zipPattern, 'i');
        return regex.test(value);
      },
    },
  };

  const updateSearchZipCode = (zipCode: string) => {
    gDispatchObj({
      type: 'SET_MAP_SEARCH_ZIPCODE',
      payload: { zipCode },
    });
  };

  const clearSearchZipCode = () => updateSearchZipCode('');

  const placeholder = `${t('Map.zipOrPostal')} ${t(
    'Map.zipOrPostalAux'
  )}`.trim();
  const ariaLabel = placeholder;

  return {
    ariaLabel,
    placeholder,
    validationRules,
    clearSearchZipCode,
    updateSearchZipCode,
    maxLength: country.zipLength,
  };
};
