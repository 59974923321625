import React, { useContext } from 'react';
import * as Styled from './styles';
import { Box, Container, Grid } from '@material-ui/core';
import { FacebookShare, TwitterShare } from './Social';
import { Copyright } from './Copyright';
import { Menu } from './Menu';
import { CountryContext } from '~/context/i18n';
import {
  BostonChildrensHospital,
  HarvardMedicalSchool,
  UniversityOfToronto,
} from '~/components/CreditLinks';

export const Footer: React.FC = () => {
  const { country } = useContext(CountryContext);

  return (
    <Styled.Footer>
      <Box py={8} px={4}>
        <Container>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item xs={12}>
              <Styled.Brand tagline white dataTest="onm-footer-logo" />
            </Grid>

            <Grid item xs={12} sm={6} md={8}>
              <Menu />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <Styled.AuxLinks>
                <BostonChildrensHospital />
                <HarvardMedicalSchool />
                {country.code === 'ca' && <UniversityOfToronto />}
                <Styled.SocialContainer>
                  <FacebookShare />
                  <TwitterShare />
                </Styled.SocialContainer>
              </Styled.AuxLinks>
            </Grid>
          </Grid>

          <Grid container>
            <Copyright />
          </Grid>
        </Container>
      </Box>
    </Styled.Footer>
  );
};
