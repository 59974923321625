import { Auth } from 'aws-amplify';

export const setPasswordForFirstSignin = async (
  user: unknown,
  newPwd: string
): Promise<void> => {
  await Auth.completeNewPassword(user, newPwd);
};

export const changePassword = async (
  oldPwd: string,
  newPwd: string
): Promise<void> => {
  const user = await Auth.currentAuthenticatedUser();
  await Auth.changePassword(user, oldPwd, newPwd);
};

export const forgotPasswordSubmit = async (
  email: string,
  code: string,
  newPassword: string
): Promise<void> => {
  await Auth.forgotPasswordSubmit(email, code, newPassword);
};
