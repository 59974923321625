import { Auth, Hub } from 'aws-amplify';
import { ISignUpResult } from 'amazon-cognito-identity-js';

export interface User {
  attributes: {
    email: string;
    username: string;
  };
}

interface Callbacks {
  onSignup?: () => void;
  onLogin?: () => void;
  onLogout?: () => void;
  onTokenRefreshFailure?: () => void;
}

type UnlistenFn = () => void;

export const signIn = async (
  email: string,
  password: string
): Promise<User> => {
  const user = await Auth.signIn(email, password);
  return user;
};

export const signOut = async (): Promise<void> => Auth.signOut();

export const getAuthToken = async (): Promise<string> => {
  const session = await Auth.currentSession();

  if (!session.isValid()) {
    throw new Error('Current user session is not valid');
  }

  return session.getIdToken().getJwtToken();
};

export const getCurrentAuthenticatedUser = async (): Promise<User> =>
  await Auth.currentAuthenticatedUser();

export const listenForAuthEvents = (callbacks: Callbacks): UnlistenFn => {
  Hub.listen('auth', data => {
    switch (data.payload.event) {
      case 'signUp':
        callbacks?.onSignup?.();
        break;
      case 'signIn':
        callbacks?.onLogin?.();
        break;
      case 'signOut':
        callbacks?.onLogout?.();
        break;
      case 'tokenRefresh_failure':
        callbacks?.onTokenRefreshFailure?.();
        break;
      default:
        break;
    }
  });

  return function() {
    Hub.remove('auth', () => undefined);
  };
};

export const signUp = async (
  username: string,
  password: string,
  nickname: string
): Promise<ISignUpResult> =>
  Auth.signUp({ username, password, attributes: { nickname } });

export const verifyAccount = async (
  email: string,
  verifyCode: string
): Promise<void> => Auth.confirmSignUp(email, verifyCode);
