import styled from 'styled-components';
import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';

export const Tabs: typeof MuiTabs = styled(MuiTabs).attrs({
  centered: true,
  variant: 'fullWidth',
  textColor: 'primary',
  indicatorColor: 'primary',
})`
  && {
    border-radius: 6px;
    background-color: ${props => props.theme.palette.background.light100};

    .MuiTab-root {
      min-width: inherit;
    }
  }
`;

export const Tab: typeof MuiTab = styled(MuiTab)`
  && {
    text-transform: none;
    font-weight: 600;
  }
`;
