const STACKNAME = process.env.GATSBY_STACKNAME;
const GOOGLE_ANALYTICS_ID = process.env.GATSBY_GOOGLE_ANALYTICS_ID;
const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;
const MAPBOX_ACCESS_TOKEN = process.env.GATSBY_MAPBOX_ACCESS_TOKEN;
const ROUTES = {
  faq: 'faq',
  collaborators: 'collaborators',
  privacyPolicy: 'privacy',
  reports: 'reports',
  share: 'share',
  terms: 'terms',
  thankYou: 'thank-you',
  whoWeAre: 'who-we-are',
  signin: 'signin',
  join: 'join',
  forgotPassword: 'forgot-password',
  resetPassword: 'reset-password',
  rapidCovidTestingSurvey: 'testing',
  trackCovidAtHomeUrl:
    'https://urldefense.com/v3/__https://trackcovidathome.org/en-us/lab/onmrat__;!!NZvER7FxgEiBAiR_!oMjEnTSaPFQrgwkj5LAwJV6mLlW2XhQUnSnResbZ5hPhghzlWIj6JB7ThM0FEFiUJsi4a2w2H5LtongRAQWGYD7XesEtCbNNdqbT$',
};

const featureToggle = {
  isAccountEnabled: process.env.ONM_IS_ACCOUNT_ENABLED || false,
};

export {
  featureToggle,
  GOOGLE_ANALYTICS_ID,
  MAPBOX_ACCESS_TOKEN,
  RECAPTCHA_SITE_KEY,
  ROUTES,
  STACKNAME,
};
