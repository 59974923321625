import React from 'react';
import ThemeProvider from '~/context/Theme';
import { RECAPTCHA_SITE_KEY } from '~/config';
import CssBaseline from '@material-ui/core/CssBaseline';
import { StoreProvider } from '~/HOC/StoreProvider.tsx';
import { GlobalContextProvider } from '~/context/global';
import { AuthStatusWatcher } from '~/HOC/AuthStatusWatcher.tsx';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import '~/services/http/config.ts';

const WrapRootElement = ({ element }) => {
  return (
    <>
      <CssBaseline />
      <StoreProvider>
        <GlobalContextProvider>
          <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY}>
            <ThemeProvider>
              <AuthStatusWatcher>{element}</AuthStatusWatcher>
            </ThemeProvider>
          </GoogleReCaptchaProvider>
        </GlobalContextProvider>
      </StoreProvider>
    </>
  );
};

export default WrapRootElement;
