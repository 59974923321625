import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';

export interface RequestConfig extends AxiosRequestConfig {
  cancelPendingRequest?: boolean;
}

export interface Response<T> {
  readonly status: number;
  readonly data: T;
}

interface HttpClient {
  get: <T>(url: string, config?: RequestConfig) => Promise<Response<T>>;
  post: <T>(
    url: string,
    data?: unknown,
    config?: RequestConfig
  ) => Promise<Response<T>>;
  put: <T>(
    url: string,
    data?: unknown,
    config?: RequestConfig
  ) => Promise<Response<T>>;
  patch: <T>(
    url: string,
    data?: unknown,
    config?: RequestConfig
  ) => Promise<Response<T>>;
  delete: <T>(url: string, config?: RequestConfig) => Promise<Response<T>>;
}

const pendingRequests: { [endpointUrl: string]: CancelTokenSource } = {};

export const httpClient: HttpClient = {
  get: <T>(url: string, config: RequestConfig = {}): Promise<Response<T>> => {
    const hasPendingRequest = pendingRequests[url];
    if (config?.cancelPendingRequest && hasPendingRequest) {
      pendingRequests[url].cancel('Canceled due to new request.');
    }
    // Save the cancel token for the current request.
    pendingRequests[url] = axios.CancelToken.source();
    config.cancelToken = pendingRequests[url].token;
    return axios.get(url, config);
  },
  post: <T>(
    url: string,
    data?: unknown,
    config?: RequestConfig
  ): Promise<Response<T>> => {
    return axios.post(url, data, config);
  },
  put: <T>(
    url: string,
    data?: unknown,
    config?: RequestConfig
  ): Promise<Response<T>> => {
    return axios.put(url, data, config);
  },
  patch: <T>(
    url: string,
    data?: unknown,
    config?: RequestConfig
  ): Promise<Response<T>> => {
    return axios.patch(url, data, config);
  },
  delete: <T>(url: string, config?: RequestConfig): Promise<Response<T>> => {
    return axios.delete(url, config);
  },
};
