/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import wrapPageWithProvider from '~/config/wrapPageElement';
import wrapRootWithProvider from '~/config/wrapRootElement';

export const wrapPageElement = wrapPageWithProvider;

export const wrapRootElement = wrapRootWithProvider;
