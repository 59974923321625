import axios from 'axios';
import { getAuthToken } from '../auth';
import { camelcase } from '~/common/casing';

axios.defaults.baseURL = '/api';

if (process.env.GATSBY_ONM_API_DEV_URL) {
  axios.defaults.baseURL = process.env.GATSBY_ONM_API_DEV_URL;
}

axios.interceptors.request.use(async request => {
  try {
    if (!request?.headers?.['Authorization']) {
      const token = await getAuthToken();
      request.headers['Authorization'] = token;
      sessionStorage.setItem('accessToken', token);
    }
    // eslint-disable-next-line no-empty
  } catch {}

  return request;
});

axios.interceptors.response.use(response => {
  response.data = camelcase(response.data, {
    recursive: true,
    recursiveInArray: true,
  });

  return response;
});
