import React, { useContext } from 'react';
import { VirusesTabs } from './VirusesTabs';
import { ActivityForm } from './ActivityForm';
import { useTranslation } from 'react-i18next';
import { CountryContext } from '~/context/i18n';
import { DataVisualizationOptions } from './DataVisualizationOptions';
import { Card } from '../Card';

export const GovernmentDataCard: React.FC = () => {
  const { t } = useTranslation();
  const { country } = useContext(CountryContext);

  const countryCode = country.code.toLowerCase();
  const countriesWithoutOfficialFluData = ['ca', 'mx'];
  const shouldRenderVirusesTabs =
    countriesWithoutOfficialFluData.includes(countryCode) === false;

  return (
    <Card title={t('LocationStats.nonUserStats.title')}>
      {shouldRenderVirusesTabs && <VirusesTabs />}
      <DataVisualizationOptions />
      <ActivityForm />
    </Card>
  );
};
