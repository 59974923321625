import React from 'react';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export { Brand, MobileBrand } from './Brand';
export { ExternalBrand } from './ExternalBrand';
export { InternalLink, ExternalLink } from './Links.tsx';

export const Section = ({ py = 8, ...restProps }) => {
  return <Box py={py} component="section" {...restProps} />;
};

export const Heading = ({
  size,
  component,
  gutterBottom = true,
  ...restProps
}) => {
  return (
    <Typography
      variant={size}
      component={component || size}
      gutterBottom={gutterBottom}
      {...restProps}
    />
  );
};

export const P = ({ variant = 'body1', ...restProps }) => {
  return (
    <Typography
      paragraph
      variant={variant}
      {...restProps}
      style={{ WebkitTextSizeAdjust: '100%' }} // todo: update MUI lib and delete this line
    />
  );
};

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const StyledOL = styled.ol`
  font-family: ${props => props.theme.fonts.montserrat};

  & li:not(:last-child) {
    margin-bottom: ${({ theme }) => `${theme.spacing(2)}px`};
  }
`;
