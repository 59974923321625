import styled from 'styled-components';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from '@material-ui/core';

export const RadioGroupFormContainer = styled(FormControl).attrs({
  fullWidth: true,
  component: 'fieldset',
})`
  && {
    padding: 0.5em 0;
  }
`;

export const RadioControlLabel = styled(FormControlLabel).attrs({
  labelPlacement: 'end',
})`
  color: ${props => props.theme.palette.common.black};
  font-family: ${props => props.theme.typography.fontFamily};
  font-size: ${props => props.theme.typography.caption.fontSize};
  font-weight: ${props => props.theme.typography.caption.fontWeight};
`;

export const StatsCaption: typeof Typography = styled(Typography)`
  color: ${props => props.theme.palette.common.black};
  font-size: ${props => props.theme.typography.caption.fontSize};
  font-weight: ${props => props.theme.typography.caption.fontWeight};
`;

export const CardFooterContainer: typeof Grid = styled(Grid).attrs({
  container: true,
  justify: 'center',
})`
  && {
    padding: 1em 1.5em 0 1.5em;
  }
`;

export const CardFooterCaption: typeof Typography = styled(Typography).attrs({
  align: 'center',
  variant: 'caption',
  color: 'textSecondary',
})`
  && {
    font-family: ${props => props.theme.typography.fontFamily};
    font-size: ${props => props.theme.typography.caption.fontSize};
    font-weight: ${props => props.theme.typography.caption.fontWeight};
  }
`;

export const StatsGradient = styled.div`
  flex-grow: 1;
  display: block;
  height: ${props => props.theme.spacing(1.5)}px;
  background: ${props => props.theme.palette.common.white};
  background: linear-gradient(
    90deg,
    rgba(240, 240, 240, 1) 0%,
    rgba(71, 71, 71, 1) 100%
  );
`;
