import React from 'react';
import breakpoint from '~/common/breakpoint';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const MapSection = styled.div`
  background: ${props => props.theme.palette.common.white};
`;

export const Content = styled(Grid).attrs({
  container: true,
})`
  padding-top: 100px;
  padding-bottom: 20px;
  ${breakpoint.sm`
    padding-bottom: 80px;
  `}
`;
export const MapStatsFormTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  padding-bottom: ${props => props.theme.spacing(4.5)}px;
  font-size: 30px;
  font-weight: 600;
  text-align: center;
`;

export const MapStatsFormContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 ${props => `${props.theme.spacing(5)}px`}
    ${props => `${props.theme.spacing(11)}px`};
`;

export const MapStatsContainer = styled(Grid)`
  ${breakpoint.sm`
    display: flex;
    flex-direction: row-reverse;
  `}
  ${breakpoint.lg`
    display: block;
    position: relative;
  `}
`;

export const MapboxCredit = styled(Typography)`
  font-size: 0.8rem;
  margin: 1rem;
  text-align: center;
  color: ${props => props.theme.palette.gray.mid};
`;

export const Title = styled(
  ({ variant = 'h4', component = 'h2', ...restProps }) => {
    return (
      <Typography variant={variant} component={component} {...restProps} />
    );
  }
)`
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  display: inline-block;
  margin: 0 auto ${props => props.theme.spacing(3)}px;
  border-bottom: 5px solid ${props => props.theme.palette.secondary.main};
`;

export const SubTitle = styled(({ variant = 'subtitle1', ...restProps }) => {
  return <Typography variant={variant} {...restProps} />;
})`
  margin: 0 auto;
  max-width: 350px;
  text-align: center;

  ${breakpoint.md`
    margin: 0 auto ${props => props.theme.spacing(6)}px;
  `}
`;
