import React from 'react';
import breakpoint from '~/common/breakpoint';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Grid from '@material-ui/core/Grid';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const StatsTypeContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const StatsNum = styled(Typography)`
  color: ${props => props.theme.palette.common.black};
  font-size: ${props => props.theme.typography.h5.fontSize};
  font-weight: bold;
`;

export const StatsCaption = styled(Typography)`
  color: ${props => props.theme.palette.common.black};
  font-size: ${props => props.theme.typography.caption.fontSize};
  font-weight: ${props => props.theme.typography.caption.fontWeight};
`;

export const Controls = styled.div`
  padding-top: ${props => props.theme.spacing(3)}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${breakpoint.sm`
    flex-direction: row;
    align-items: flex-start;
  `}
  ${breakpoint.md`
    flex-direction: column;
    align-items: center;
  `}
`;

export const ControlContainer = styled(Paper).attrs({
  elevation: 0,
})`
  width: 100%;
  padding: 0 1.25rem 1.25rem;
  background: transparent;

  ${breakpoint.sm`
    &:first-child {
      padding-right: 0.625rem;
    }
    &:last-child {
      padding-left: 0.625rem;
    }
  `}

  ${breakpoint.md`
  &,
  &:first-child,
  &:last-child {

    padding-top: 0;
    padding-right: 0;
    padding-bottom: ${props => `${props.theme.spacing(3)}px`};
    padding-left: ${props => `${props.theme.spacing(3.75)}px`};
  }
  `}

  ${breakpoint.lg`
  &,
  &:first-child,
  &:last-child {

    padding-left: ${props => `${props.theme.spacing(8.5)}px`};
    z-index: 2;
  }
  `}
`;

export const StatsContainerRow = styled(({ children, ...props }) => (
  <Grid item xs={12} {...props}>
    {children}
  </Grid>
))`
  display: inline-flex;
  align-items: flex-start;
  flex-basis: auto;
`;

export const CustomListItemIcon = styled(ListItemIcon)`
  && {
    min-width: 25px;
    padding-top: 0.33rem;
    color: ${props => props.color};
  }
`;

export const CircleIcon = styled(FiberManualRecordIcon)`
  && {
    font-size: 25px;
  }
`;
