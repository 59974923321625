import React, { useEffect, useContext } from 'react';
import { useZipCode } from './useZipCode';
import { useForm } from 'react-hook-form';
import { ZipCodeInput } from './ZipCodeInput';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '~/context/global';
import { CountryContext } from '~/context/i18n';

export const MapStatsForm: React.FC = () => {
  const { t } = useTranslation();
  const { validationRules, updateSearchZipCode } = useZipCode();
  const { country } = useContext(CountryContext);

  const {
    searchZipCode,
    zipCodeSearchError,
    isGovernmentDataLoading,
    isSelfReportedDataLoading,
    isGeoJsonMarkerDataLoading,
  } = useGlobalState();

  const form = useForm<{ zipCode: string }>();
  const zipCodeInput = form.watch('zipCode', searchZipCode);

  const isMapDataLoading =
    isGovernmentDataLoading ||
    isSelfReportedDataLoading ||
    isGeoJsonMarkerDataLoading;

  const searchStatsForZipCode = form.handleSubmit(({ zipCode }) => {
    if (zipCode && !form.errors.zipCode) {
      updateSearchZipCode(zipCode);
    }
  });

  const clearZipCodeInput = () =>
    form.setValue('zipCode', '', { shouldValidate: false, shouldDirty: false });

  const resetZipCode = () => {
    updateSearchZipCode('');
    clearZipCodeInput();
  };

  const validateOnBlur = () => {
    if (zipCodeInput) form.trigger('zipCode');
    else form.clearErrors();
  };

  const onZipCodeInputAdornmentButtonClick = () => {
    if (!searchZipCode) searchStatsForZipCode();
    else resetZipCode();
  };

  useEffect(
    function clearZipCodeInputOnSearchError() {
      if (zipCodeSearchError) clearZipCodeInput();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, zipCodeSearchError]
  );

  useEffect(
    function clearZipCodeOnCountryChange() {
      clearZipCodeInput();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [country]
  );

  const getErrorMessage = () => {
    const errorType = form.errors?.zipCode?.type;
    return errorType ? t(`FormField.zipcode.errors.${errorType}`) : '';
  };

  return (
    <form>
      <ZipCodeInput
        onBlur={validateOnBlur}
        loading={isMapDataLoading}
        disabled={!!searchZipCode}
        errorMessage={getErrorMessage()}
        ref={form.register(validationRules)}
        onAdornmentButtonClick={onZipCodeInputAdornmentButtonClick}
      />
    </form>
  );
};
