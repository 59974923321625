import React from 'react';
import { useSelector } from '~/store';
import { FluActivityForm } from './FluActivityForm';
import { CovidActivityForm } from './CovidActivityForm';
import {
  isVisualizingFluData,
  isVisualizingCovidData,
} from '~/store/map/filters';

export const ActivityForm: React.FC = () => {
  const { governmentDataVirus } = useSelector(state => state.mapFilters);

  if (isVisualizingCovidData(governmentDataVirus)) {
    return <CovidActivityForm />;
  }

  if (isVisualizingFluData(governmentDataVirus)) {
    return <FluActivityForm />;
  }
};
