import styled from 'styled-components';
import {
  Card as MuiCard,
  CardHeader as MuiCardHeader,
} from '@material-ui/core';

export const Card: typeof MuiCard = styled(MuiCard).attrs({
  variant: 'outlined',
})`
  && {
    padding: 30px;
    background-color: ${props => props.theme.palette.common.white};
  }
`;

export const CardHeader: typeof MuiCardHeader = styled(MuiCardHeader).attrs({
  titleTypographyProps: {
    align: 'center',
    variant: 'overline',
    color: 'textSecondary',
  },
})`
  && {
    padding-top: 0;
    padding-bottom: ${props => props.theme.spacing(2.5)}px;
    .MuiTypography-overline {
      font-size: 1em;
      line-height: 22px;
    }
  }
`;
