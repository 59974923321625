import React from 'react';
import { useSelector } from '~/store';
import { useTranslation } from 'react-i18next';
import { useMapFilters } from '~/components/Map/useMapFilters';
import { isVisualizingFluData, GovernmentDataVirus } from '~/store/map/filters';
import * as Styled from './styles';

export const VirusesTabs: React.FC = () => {
  const { t } = useTranslation();
  const { switchGovernmentDataVirus } = useMapFilters();
  const { governmentDataVirus } = useSelector(state => state.mapFilters);

  const fluTab = 0;
  const covidTab = 1;
  const fluTabIsSelected = isVisualizingFluData(governmentDataVirus);
  const tab = fluTabIsSelected ? fluTab : covidTab;

  const onChange = () => {
    switchGovernmentDataVirus(
      fluTabIsSelected ? GovernmentDataVirus.COVID : GovernmentDataVirus.FLU
    );
  };

  return (
    <Styled.Tabs data-testid="virus_tabs" value={tab} onChange={onChange}>
      <Styled.Tab
        data-test="flu_virus_tab"
        label={t('LocationStats.nonUserStats.flu')}
      />
      <Styled.Tab data-test="covid_virus_tab" label="COVID-19" />
    </Styled.Tabs>
  );
};
