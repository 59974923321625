import { formatISO } from 'date-fns';
import { logger } from '~/services/logging/index.ts';

export const isBrowser = typeof window !== 'undefined';

export const setSelectedFormValuesToTrue = itemArr => {
  const retValue = {};
  itemArr.forEach(tuple => {
    retValue[tuple] = true;
  });
  return retValue;
};

export const trim = (str = '', characterToBeTrimmed = '/') => {
  const trimmed = str.endsWith(characterToBeTrimmed) ? str.slice(0, -1) : str;
  return trimmed.startsWith(characterToBeTrimmed) ? trimmed.slice(1) : trimmed;
};

export const applyNewLangToRoute = ({ route, newLang, oldLang }) => {
  return route.replace(trim(oldLang), trim(newLang));
};

export const convertDateToISO = date => {
  const dateObj = date instanceof Date ? date : new Date(date);
  return formatISO(dateObj, {
    representation: 'date',
  });
};

/**
 * Utility function for converting an ISO formatted date of birth string into a Date.
 * Note: we need to subtract 1 from the month, since months are zero based in JS
 * Note: the day is not relevant so we default it to the 1st of the month
 * @param {string} dobISOString
 * @return {Date}
 */
export const getDateOfBirthFromISO = dobISOString => {
  if (!dobISOString) return null;
  const dobArray = dobISOString.split('-');
  if (dobArray.length !== 3) {
    const error = new Error('Error getting DoB from ISO string');
    logger.error('DATE_OF_BIRTH', error);
    return null;
  }
  const [year, month] = dobArray;
  return new Date(year, month - 1, 1);
};

export const removeCountryPhoneCodePrefix = (
  phoneNumber,
  countryPhoneCodes
) => {
  return countryPhoneCodes.reduce((phone, code) => {
    return phone.replace(code, '');
  }, phoneNumber);
};

export const oldestBirthYear = new Date().getFullYear() - 125;
export const youngestBirthYear = new Date().getFullYear() - 13;
export const defaultDateOfBirth = new Date(1998, new Date().getMonth());

export const getActiveHouseholdMembers = householdMembers =>
  householdMembers.filter(member => member.isActive);

export const getDuplicateHouseholdNames = members => {
  const duplicateNames = new Set();
  const comparedNames = new Set();
  for (const m of members) {
    comparedNames.has(m.name)
      ? duplicateNames.add(m.name)
      : comparedNames.add(m.name);
  }
  return duplicateNames;
};
