import { account, Account, Ethnicity, Gender, Race } from './account';
import { VaccinationValue } from '~/common/vaccinations';
import { ethnicityIdFieldName } from '~/components/forms/constants';

export interface UserState {
  account: Account;
  loggedIn?: boolean;
}

export interface UserDemographics {
  birthdate?: string;
  gender?: Gender;
  raceId?: Race;
  [ethnicityIdFieldName]?: Ethnicity;
  userSubmittedRace?: string;
  zipcode?: string;
  fluSeason?: string;
  covidSeason?: string;
  receivedFluVaccine?: VaccinationValue;
  receivedCovidVaccine?: VaccinationValue;
}

export const userState: UserState = {
  account,
};
