import React from 'react';
import * as Styled from './styles';
import { useSelector } from '~/store';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { useMapFilters } from '~/components/Map/useMapFilters';

export const DataVisualizationOptions: React.FC = () => {
  const { t } = useTranslation();
  const ariaLabel = t('LocationStats.nonUserStats.togglePopulationData');
  const { toggleGovernmentData } = useMapFilters();
  const { governmentData: showGovernmentData } = useSelector(
    state => state.mapFilters
  );

  return (
    <Styled.Form>
      <Typography component="div">
        <Styled.Grid>
          <Grid item>
            <Styled.Label>
              {t('LocationStats.nonUserStats.toggleOfficialData')}
            </Styled.Label>
          </Grid>
          <Grid item>
            <Styled.Switch
              name="officialData"
              checked={showGovernmentData}
              onChange={toggleGovernmentData}
              inputProps={{ 'aria-label': ariaLabel }}
            />
          </Grid>
        </Styled.Grid>
      </Typography>
    </Styled.Form>
  );
};
