import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '~/context/global';
import { ExternalLink } from '~/components/BaseElements';
import { isVisualizingCovidDeathsData } from '~/store/map/filters';
import { CountryContext } from '~/context/i18n';
import { Grid } from '@material-ui/core';
import { useSelector } from '~/store';
import * as Styled from './styles';

export const Stats: React.FC = () => {
  const { i18n, t } = useTranslation();
  const { country } = useContext(CountryContext);
  const countryCode = country?.code.toUpperCase();
  const { statsByCountry } = useGlobalState();
  const { governmentCovidActivity } = useSelector(state => state.mapFilters);

  const currentCountryStats = statsByCountry[countryCode];
  const scaleMin = 0;
  const scaleBase = 100000;
  let scaleMax = 0;
  if (currentCountryStats) {
    scaleMax = isVisualizingCovidDeathsData(governmentCovidActivity)
      ? currentCountryStats.populationData?.maxIRateDeath
      : currentCountryStats.populationData?.maxIRateConfirm;
  }

  const dataSourceLink = 'https://ourworldindata.org/covid-cases';
  const dataSourceText = t('LocationStats.nonUserStats.dataSource');
  const totalPeopleText = t('LocationStats.nonUserStats.totalCovid', {
    number: scaleBase.toLocaleString(i18n.language),
  });

  return (
    <Grid container direction="column">
      <Grid container alignItems="center" justifyContent="space-between">
        <Styled.StatsCaption>
          {scaleMin.toLocaleString(i18n.language)}
        </Styled.StatsCaption>
        <Styled.StatsCaption>
          {scaleMax?.toLocaleString(i18n.language)}
        </Styled.StatsCaption>
      </Grid>

      <Styled.StatsGradient />

      <Styled.CardFooterContainer>
        <Styled.CardFooterCaption>
          {totalPeopleText}
          <Link to={dataSourceLink}>{' ' + dataSourceText}</Link>
        </Styled.CardFooterCaption>
      </Styled.CardFooterContainer>
    </Grid>
  );
};

const Link = ExternalLink as React.FC<{ to: string }>;
