import { Auth } from 'aws-amplify';
import { getCurrentAuthenticatedUser } from './authSession';

interface UpdatableUserAttributes {
  email?: string;
}

export const updateUserAttributes = async (
  attributes: UpdatableUserAttributes
): Promise<void> => {
  const user = await getCurrentAuthenticatedUser();
  await Auth.updateUserAttributes(user, attributes);
};
