import React from 'react';
import { ROUTES } from '~/config';
import BackButton from './BackButton';
import MenuLanguage from './MenuLanguage';
import { trim } from '~/common/generalUtils';
import { Brand, MobileBrand } from '~/components/BaseElements';
import { Hidden } from '@material-ui/core';
import { AuthHeader } from './AuthHeader.tsx';
import * as Styled from './styles';

const HeaderContent = props => (
  <Styled.HeaderWrapper>
    <Hidden smDown>
      <Styled.BrandWrapper>
        <Brand dataTest="onm-header-logo" />
        <Styled.Divider />
        <MenuLanguage pathName={props.location.pathname} />
      </Styled.BrandWrapper>
    </Hidden>

    <Hidden mdUp>
      <Styled.MobileLanguageWrapper>
        <MenuLanguage pathName={props.location.pathname} />
      </Styled.MobileLanguageWrapper>
      <MobileBrand />
    </Hidden>

    <AuthHeader pathName={props.location.pathname} />
  </Styled.HeaderWrapper>
);

const Header = props => {
  const { location } = props;
  const routeParts = trim(location.pathname).split('/');
  const currentRoute = routeParts[routeParts.length - 1];
  const isSubpage = [
    ROUTES.faq,
    ROUTES.collaborators,
    ROUTES.privacyPolicy,
    ROUTES.reports,
    ROUTES.terms,
    ROUTES.whoWeAre,
  ].includes(currentRoute);

  return (
    <Styled.AppBar isSubpage={isSubpage} elevation={0}>
      <Styled.AppBarContent>
        {isSubpage ? <BackButton /> : <HeaderContent {...props} />}
      </Styled.AppBarContent>
    </Styled.AppBar>
  );
};

export default Header;
