import React, { useEffect } from 'react';
import { useAccountState } from '~/state/hooks/useAccountState';
import { signOut } from '~/services/auth';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useGlobalState } from '~/context/global';
import { useTranslation } from 'react-i18next';
import { useSelector } from '~/store';

export const UserAccountFetcher: React.FC = ({ children }) => {
  const { fetchAndUpdateAccountState } = useAccountState();
  const { loggedIn } = useSelector(state => state.userAuth);
  const { account } = useGlobalState().user;
  const { showSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const userAccountShouldBeFetched =
    !!loggedIn && !Object.keys(account || {}).length;

  const fetchAccount = async () => {
    if (userAccountShouldBeFetched) {
      try {
        fetchAndUpdateAccountState();
      } catch (error) {
        await signOut();
        showSnackbar(t('Account.signInError'), 'error');
      }
    }
  };

  useEffect(() => {
    fetchAccount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn]);

  return <>{children}</>;
};
