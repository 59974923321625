import React, { forwardRef } from 'react';
import { FilledInputProps } from '@material-ui/core';
import { useZipCode } from '../useZipCode';
import * as Styled from './styles';
import { InputAdornmentButton } from './InputAdornmentButton';

interface Props extends FilledInputProps {
  loading: boolean;
  errorMessage?: string;
  onAdornmentButtonClick: () => void;
}

export const ZipCodeInput: React.FC<Props> = forwardRef((props, ref) => {
  const { ariaLabel, placeholder, maxLength } = useZipCode();

  const submitFormOnEnterKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key.toLocaleLowerCase() === 'enter') {
      event.preventDefault();
      props.onAdornmentButtonClick();
    }
  };

  return (
    <Styled.ZipCodeInputContainer>
      <Styled.ZipCodeInput
        inputRef={ref}
        onBlur={props.onBlur}
        placeholder={placeholder}
        error={!!props.errorMessage}
        onKeyPress={submitFormOnEnterKeyPress}
        disabled={props.disabled || props.loading}
        inputProps={{ maxLength, 'aria-label': ariaLabel }}
        endAdornment={
          <InputAdornmentButton
            loading={props.loading}
            disabled={!!props.errorMessage}
            onClick={props.onAdornmentButtonClick}
            buttonAction={props.disabled ? 'clear' : 'submit'}
          />
        }
      />
      <Styled.ZipCodeInputErrorText data-test="map_zipcode_input_error">
        {props.errorMessage}
      </Styled.ZipCodeInputErrorText>
    </Styled.ZipCodeInputContainer>
  );
});
