import { useSnackbar as useSnack, VariantType } from 'notistack';

interface UseSnackbar {
  showSnackbar: (
    message: string,
    variant: VariantType,
    onClose?: () => void
  ) => void;
}

export const useSnackbar = (): UseSnackbar => {
  const { enqueueSnackbar } = useSnack();

  const showSnackbar = (
    message: string,
    variant: VariantType,
    onClose?: () => void
  ) => {
    enqueueSnackbar(message, {
      variant,
      onClose,
      autoHideDuration: 3000,
      preventDuplicate: true,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    });
  };

  return { showSnackbar };
};
