import { css } from 'styled-components';

/*
Breakpoint Helper:
create media query shortcuts to use in styled components. 
https://medium.com/@samuelresua/easy-media-queries-in-styled-components-690b78f50053

example: 
  ${breakpoint.sm`
    background: red
  `}
*/

const defaultMuiSizes = ['xs', 'sm', 'md', 'lg', 'xl'];

const breakpoint = defaultMuiSizes.reduce((acc, label) => {
  acc[label] = (...args) => css`
    ${props => css`
      ${props.theme.breakpoints.up(label)} {
        ${css(...args)}
      }
    `}
  `;
  return acc;
}, {});

export default breakpoint;
