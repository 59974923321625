import React from 'react';
import styled from 'styled-components';
import MenuIcon from '@material-ui/icons/Menu';
import { InternalLink } from '~/components/BaseElements';
import { RegularCaseButton } from '~/components/forms/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import {
  Grid,
  MenuItem,
  Button,
  AppBar as UnstyledAppBar,
  Divider as UnstyledDivider,
} from '@material-ui/core';

export const AppBar = styled(({ isScrolled, isSubpage, ...restProps }) => (
  <UnstyledAppBar {...restProps} />
))`
  height: 56px;
  align-items: center;
  justify-content: space-between;
  background: ${props => alpha(props.theme.palette.common.white, 0.9)};
`;

export const AppBarContent = styled(Grid).attrs({
  container: true,
  direction: 'row',
  alignItems: 'center',
  justify: 'space-between',
})`
  width: 100%;
  height: inherit;
  padding: 0 20px;

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1256px;
  }
`;

export const Divider = styled(UnstyledDivider).attrs({
  orientation: 'vertical',
  variant: 'middle',
  flexItem: true,
})`
  align-self: center;
  height: 32px;
`;

export const HorizontalDivider = styled(UnstyledDivider).attrs({
  orientation: 'horizontal',
  variant: 'middle',
  flexItem: true,
})`
  align-self: center;
  height: 1px;
`;

export const HeaderWrapper = styled(Grid).attrs({
  container: true,
  direction: 'row',
  alignItems: 'center',
  wrap: 'nowrap',
})`
  width: 100%;
  padding: 0;

  ${props => props.theme.breakpoints.up('lg')} {
    max-width: 1256px;
  }
`;

export const BrandWrapper = styled(Grid).attrs({
  container: true,
  direction: 'row',
  alignItems: 'center',
  justify: 'flex-start',
})``;

export const MobileLanguageWrapper = styled.div`
  flex: 1;
`;

export const AccountButtonsWrapper = styled(Grid).attrs({
  container: true,
  direction: 'row',
  alignItems: 'center',
  justify: 'flex-end',
  wrap: 'nowrap',
})``;

export const SignInButton = styled(Button)`
  white-space: nowrap;
  font-weight: 600;
  color: ${props => props.theme.palette.primary.main};
  ${props => props.theme.breakpoints.down('sm')} {
    border: 1px solid rgba(0, 140, 183, 0.5);
  }
`;

export const JoinButton = styled(Button).attrs({
  color: 'primary',
})`
  font-weight: 600;
  margin-left: 8px;
`;

export const BackButton = styled(InternalLink)`
  color: ${props => props.theme.palette.primary.main};
  display: inline-flex;
  align-items: inherit;
  font-size: 1.1rem;
  font-weight: 300;

  svg {
    margin-right: ${props => props.theme.spacing(1.5)}px;
  }
`;

export const MobileMenuButtonWrapper = styled.div`
  flex: 1;
`;

export const AuthHeaderWrapper = styled(Grid).attrs({
  container: true,
  direction: 'row',
  alignItems: 'center',
  justify: 'flex-end',
  wrap: 'nowrap',
})`
  flex: 1;
`;

export const MobileMenuButton = styled(Button).attrs({
  edge: 'start',
  endIcon: <MenuIcon />,
})`
  text-transform: none;
`;

export const MenuItems = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: fit-content;
    margin-right: ${props => `${props.theme.spacing(1)}px`};
  }

  &:focus {
    background-color: ${props => props.theme.palette.secondary.main};

    .MuiListItemIcon-root,
    .MuiListItemText-primary {
      color: ${props => props.theme.palette.common.white};
    }
  }
`;

export const Flag = styled.img`
  width: 20px;
  height: 20px;
`;

export const ChangeLanguageButton = styled(RegularCaseButton)`
  color: ${props => props.theme.palette.common.black};
  border: none;
  text-align: left;

  :hover {
    border: none;
    background-color: ${props =>
      alpha(props.theme.palette.background.gray, 0.2)};
  }

  .country-lang {
    width: 100%;
  }

  .MuiButton-outlined {
    padding-left: 0;
  }
`;

export const DropDownButton = styled(RegularCaseButton)`
  color: ${props => props.theme.palette.common.black};
  border: none;
  text-align: left;
  white-space: nowrap;

  :hover {
    border: none;
    background-color: ${props =>
      alpha(props.theme.palette.background.gray, 0.2)};
  }

  .MuiButton-outlined {
    padding-left: 0;
  }
`;
