import React from 'react';
import Layout from '~/components/Layout';
import { UserAccountFetcher } from '~/HOC/UserAccountFetcher.tsx';
import I18nProvider from '../context/i18n/index.tsx';

const WrapPageElement = ({ element, props }) => {
  return (
    <>
      <I18nProvider {...props}>
        <UserAccountFetcher>
          <Layout {...props}>{element}</Layout>
        </UserAccountFetcher>
      </I18nProvider>
    </>
  );
};

export default WrapPageElement;
