import React from 'react';
import * as Styled from './styles';
import { Grid } from '@material-ui/core';
import { InternalLink } from '~/components/BaseElements';

interface Props {
  message: string;
  route: string;
  link: string;
}

export const Banner: React.FC<Props> = ({ message, route, link }) => {
  return (
    <Styled.BannerContainer spacing={1}>
      <Grid item>
        <Styled.Text>{message}</Styled.Text>
      </Grid>
      <Grid item>
        <InternalLink to={route}>
          <Styled.Text>{link}</Styled.Text>
        </InternalLink>
      </Grid>
    </Styled.BannerContainer>
  );
};
