import routes from '../routes';
import { httpClient, Response } from '~/services/http';
import {
  ONMFeature,
  ErrorContext,
  APIEndpointError,
} from '~/services/logging/types';

interface LogErrorRequestBody {
  error: Error | APIEndpointError;
  onmFeature: ONMFeature;
  context?: ErrorContext;
}

export const logToBackend = (
  requestBody: LogErrorRequestBody
): Promise<Response<undefined>> => {
  return httpClient.post<undefined>(routes.logging, requestBody);
};
