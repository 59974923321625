import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup } from '@material-ui/core';
import { useMapFilters } from '~/components/Map/useMapFilters';
import { GovernmentCovidActivity } from '~/store/map/filters';
import { useSelector } from '~/store';
import * as Styled from './styles';

export const Form: React.FC = () => {
  const { t } = useTranslation();
  const { switchGovernmentCovidActivity } = useMapFilters();
  const { governmentCovidActivity } = useSelector(state => state.mapFilters);

  const handleChange = (_, value: GovernmentCovidActivity) => {
    switchGovernmentCovidActivity(value);
  };

  return (
    <Styled.RadioGroupFormContainer>
      <RadioGroup
        row
        onChange={handleChange}
        name="official-data-type"
        aria-label="official-data-type"
        value={governmentCovidActivity}
      >
        <Styled.RadioControlLabel
          control={<Radio color="primary" />}
          value={GovernmentCovidActivity.CASES}
          label={t('LocationStats.nonUserStats.cases')}
        />
        <Styled.RadioControlLabel
          control={<Radio color="primary" />}
          value={GovernmentCovidActivity.DEATHS}
          label={t('LocationStats.nonUserStats.deaths')}
        />
      </RadioGroup>
    </Styled.RadioGroupFormContainer>
  );
};
