import styled from 'styled-components';
import {
  FormGroup,
  Typography,
  Grid as MuiGrid,
  Switch as MuiSwitch,
} from '@material-ui/core';
import breakpoint from '~/common/breakpoint';

export const Form = styled(FormGroup)`
  && {
    padding-top: 10px;
    padding-bottom: 20px;
  }
`;

export const Grid: typeof MuiGrid = styled(MuiGrid).attrs({
  spacing: 1,
  container: true,
  component: 'label',
  alignItems: 'center',
  justify: 'space-between',
})``;

export const Switch = styled(MuiSwitch).attrs({
  edge: 'end',
  color: 'primary',
})``;

export const Label = styled(Typography)`
  ${breakpoint.sm`
    font-size: 0.9rem;
  `}

  ${breakpoint.md`
    font-size: 1rem;
  `}
`;
