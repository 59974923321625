import React, { RefObject } from 'react';
import Link from '@material-ui/core/Link';
import { Link as GatsbyLink } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { trim } from '~/common/generalUtils';
import { useLanguageUrl } from '~/state/hooks/useLanguageUrl';

interface LinkProps {
  to?: string;
  state?: unknown;
  className?: string;
}

interface ExternalLinkProps {
  to?: string;
  className?: string;
}

export const InternalLink: React.FC<LinkProps> = React.forwardRef(
  (
    props,
    ref: RefObject<GatsbyLink<unknown>> & RefObject<HTMLAnchorElement>
  ) => {
    const baseUrl = useLanguageUrl();
    const localUrl = `/${trim(`${baseUrl}${props.to}`)}/`;

    return (
      <Link
        ref={ref}
        to={localUrl}
        state={props.state}
        component={GatsbyLink}
        className={props.className}
      >
        {props.children}
      </Link>
    );
  }
);

export const ExternalLink: React.FC<ExternalLinkProps> = React.forwardRef(
  (
    props,
    ref: RefObject<GatsbyLink<unknown>> & RefObject<HTMLAnchorElement>
  ) => (
    <Link
      component={OutboundLink}
      href={props.to}
      ref={ref}
      rel="noopener noreferrer"
      target="_blank"
      {...props}
    />
  )
);
