import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import * as Styled from './styles';
import Grid from '@material-ui/core/Grid';
import { ImageDivider } from './styles';
import Box from '@material-ui/core/Box';

export const WhatIsIt: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.WhatIsItSection data-test="what_is_it_section">
      <Grid container>
        <Box clone order={{ xs: 3, sm: 2 }}>
          <Grid item xs={12} md={5}>
            <ImageDivider />
          </Grid>
        </Box>
        <Box clone order={{ xs: 2, sm: 3 }}>
          <Grid item xs={12} md={7}>
            <Styled.TextContainer>
              <Styled.WhatIsItTitle>{t('WhatIsIt.title')}</Styled.WhatIsItTitle>
              <Styled.WhyIsItSubtitle>
                <Trans i18nKey="WhatIsIt.subtitle"></Trans>
              </Styled.WhyIsItSubtitle>
              <Styled.Description>{t('WhatIsIt.p1')}</Styled.Description>
              <Styled.Description>{t('WhatIsIt.p2')}</Styled.Description>
            </Styled.TextContainer>
          </Grid>
        </Box>
      </Grid>
    </Styled.WhatIsItSection>
  );
};
