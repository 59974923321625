import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Styled from './styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import analyzeGraphic from '~/assets/images/home.how-does-it-work.analyze.svg';
import communityGraphic from '~/assets/images/home.how-does-it-work.community.svg';
import contributeGraphic from '~/assets/images/home.how-does-it-work.contribute.svg';
import { HowItWorksItem } from './HowItWorksItem';

export const HowItWorks: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Styled.HowItWorksSection data-test="how_does_it_work_section">
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={11} md={10}>
            <Styled.Title>{t('HowItWorks.title')}</Styled.Title>
            <Grid container>
              <HowItWorksItem id="contribute" image={contributeGraphic} />
              <HowItWorksItem id="community" image={communityGraphic} />
              <HowItWorksItem id="analyze" image={analyzeGraphic} />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Styled.HowItWorksSection>
  );
};
