import React from 'react';
import { signOut } from '~/services/auth';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from '~/context/global';
import { useSnackbar } from '~/state/hooks/useSnackbar';
import { useNavigate } from '~/state/hooks/useNavigate';
import { List, ListItemText } from '@material-ui/core';
import * as Styled from './styles';

interface SignedInAsProps {
  closeDrawer: () => void;
}

interface DrawerContentProps extends SignedInAsProps {
  pathName?: string;
}

export const DrawerContent: React.FC<DrawerContentProps> = ({
  closeDrawer,
}) => {
  const { t } = useTranslation();
  const username = useGlobalState().user?.account?.nickname;
  const { navigate } = useNavigate();
  const { showSnackbar } = useSnackbar();

  const goToAccountSettings = () => navigate('account-settings');
  const attemptSignOut = () =>
    signOut().catch(() => showSnackbar(t('Account.signOutError'), 'error'));

  return (
    <List disablePadding={true}>
      <Styled.MenuHeading>
        {t('Account.welcome', { username })}
      </Styled.MenuHeading>
      <Styled.Divider />
      <Styled.MenuItem
        onClick={goToAccountSettings}
        onClickCapture={closeDrawer}
      >
        <ListItemText primary={t('AccountSettings.pageTitle')} />
      </Styled.MenuItem>
      <Styled.MenuItem onClick={attemptSignOut} onClickCapture={closeDrawer}>
        <ListItemText primary={t('Account.signOut')} />
      </Styled.MenuItem>
    </List>
  );
};
