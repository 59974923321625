import { useDispatch } from '~/store';
import {
  updateMapFilter,
  updateBooleanMapFilter,
  GovernmentCovidActivity,
  GovernmentDataVirus,
  NonBooleanFilterValue,
  NonBooleanMapFilter,
  BooleanMapFilter,
} from '~/store/map/filters';

interface UseMapFilters {
  toggleUserReportedFluSymptoms: () => void;
  toggleUserReportedCovidSymptoms: () => void;
  toggleUserReportedOtherSymptoms: () => void;
  toggleUserReportedNoSymptoms: () => void;

  toggleGovernmentData: () => void;
  switchGovernmentDataVirus: (filterValue: GovernmentDataVirus) => void;
  switchGovernmentCovidActivity: (filterValue: GovernmentCovidActivity) => void;
}

interface NonBooleanFilterUpdatePayload {
  filter: NonBooleanMapFilter;
  value: NonBooleanFilterValue;
}

/**
 * Custom hook for manipulating the map's filters.
 *
 * It exposes an API with a defined set of functions that components can use to update the "filters" property of the store.
 *
 * The map should listen for these store updates and react accordingly.
 */

export const useMapFilters = (): UseMapFilters => {
  const dispatch = useDispatch();

  const toggleBooleanFilter = (filter: BooleanMapFilter) => {
    dispatch(updateBooleanMapFilter(filter));
  };

  const toggleNonBooleanFilter = (payload: NonBooleanFilterUpdatePayload) => {
    dispatch(updateMapFilter(payload));
  };

  const toggleUserReportedFluSymptoms = () =>
    toggleBooleanFilter(BooleanMapFilter.USER_REPORTED_FLU_SYMPTOMS);
  const toggleUserReportedCovidSymptoms = () =>
    toggleBooleanFilter(BooleanMapFilter.USER_REPORTED_COVID_SYMPTOMS);
  const toggleUserReportedOtherSymptoms = () =>
    toggleBooleanFilter(BooleanMapFilter.USER_REPORTED_OTHER_SYMPTOMS);
  const toggleUserReportedNoSymptoms = () =>
    toggleBooleanFilter(BooleanMapFilter.USER_REPORTED_NO_SYMPTOMS);
  const toggleGovernmentData = () =>
    toggleBooleanFilter(BooleanMapFilter.GOVERNMENT_DATA);

  const switchGovernmentDataVirus = (value: GovernmentDataVirus) => {
    toggleNonBooleanFilter({
      value,
      filter: NonBooleanMapFilter.GOVERNMENT_DATA_VIRUS,
    });
  };

  const switchGovernmentCovidActivity = (value: GovernmentCovidActivity) => {
    toggleNonBooleanFilter({
      value,
      filter: NonBooleanMapFilter.GOVERNMENT_COVID_ACTIVITY,
    });
  };

  return {
    toggleUserReportedFluSymptoms,
    toggleUserReportedCovidSymptoms,
    toggleUserReportedOtherSymptoms,
    toggleUserReportedNoSymptoms,
    toggleGovernmentData,
    switchGovernmentDataVirus,
    switchGovernmentCovidActivity,
  };
};
