import styled from 'styled-components';
import {
  Box,
  Button,
  Divider as MuiDivider,
  Drawer as MuiDrawer,
  MenuItem as MuiMenuItem,
} from '@material-ui/core';

export const Drawer = styled(MuiDrawer).attrs({
  anchor: 'bottom',
})``;

export const DrawerContainer = styled.div`
  flex: 1;
  flex-direction: column;
  padding: 2rem;
`;

export const MenuHeading = styled(MuiMenuItem)`
  padding: 0.5rem 0 1rem;
  font-size: 1.5rem;
  font-weight: 200;
`;

export const MenuItem = styled(MuiMenuItem)`
  padding: 0.5rem 0;
  color: ${props => props.theme.palette.primary.main};
`;

export const Divider = styled(MuiDivider)`
  margin-bottom: 1rem;
  background-color: ${props => props.theme.palette.background.gray90};
`;

export const ButtonsContainer = styled(Box).attrs({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
})`
  padding: 30px 0;
`;

export const BaseButton: typeof Button = styled(Button).attrs({
  color: 'primary',
  variant: 'contained',
})`
  height: 36px;
  width: 130px;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: capitalize;
  box-shadow: none;
`;

export const JoinButton: typeof Button = styled(BaseButton)``;
export const SignInButton: typeof Button = styled(BaseButton)`
  background-color: ${props => props.theme.palette.common.white};
  color: ${props => props.theme.palette.primary.main};
`;
